import { CircularProgress } from "@mui/material";
import "./demoAccountLoading.css";
const DemoAccountLoading = () => {
  return (
    <button
      style={{ cursor: "default" }}
      className={"demo_loading_acc"}
      disabled={true}
    >
      <CircularProgress />
    </button>
  );
};

export default DemoAccountLoading;
