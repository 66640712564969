import AccountCard from "../AccountCard/AccountCard";
import tooltip_info_icon from "../../assets/tooltip_info.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  resetDisabledAutomation,
  setSelectedAutomation,
} from "../../redux/sliceUserAutomation";
import { getTransferDirectionInfo } from "../../helpers/transfersUtils";
import PlaidAuth from "../../pages/Plaid/PlaidAuth";
import Button from "../Button/Button";
import notification from "../ToastNotifications/ToastNotifications";
import { setReconnectError } from "../../redux/sliceNotifications";
// @ts-ignore

import arrows from "../../assets/arrows_vertical.png";
import arrows_red from "../../assets/arrows_red_vertical.png";
import { Tooltip } from "@mui/material";
import React from "react";
import { useMixpanel } from "../../helpers/mixpanel";
import "./cardsBlock.css";
const CardsBlock = ({
  automation,
  showInitedTransaction,
  transactionAmount,
  noTransactionInitiated,
}: {
  automation: {
    pendingTransaction: { [field: string]: any };
    direction: 0 | 1;
    checkingAccount: { [field: string]: any };
    savingAccount: { [field: string]: any };
    fixedValue: string;
    status: any;
    id: string;
  };
  showInitedTransaction?: boolean;
  transactionAmount?: number;
  noTransactionInitiated?: boolean;
}) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const editAutomationHandler = () => {
    // dispatch(setSelectedAutomation(automation));
    // navigation(`/automation-edit/${automation.id}`);
  };

  const {
    status,
    pendingTransaction,
    checkingAccount,
    savingAccount,
    fixedValue,
  } = automation;

  const { to } = getTransferDirectionInfo({
    direction: pendingTransaction?.direction,
  });

  const accessToken = checkingAccount.accessToken;

  const reloadPage = () => {
    notification({
      message: "Reconnection successful. Automation resumed.",
      type: "success",
    });
    dispatch(resetDisabledAutomation());
    dispatch(setReconnectError(false));
    navigation("/automations");
  };

  const shouldShowAutomationPausedMsg = status === "reconnection_need";
  const shouldShowTransferInitMsg =
    !shouldShowAutomationPausedMsg && pendingTransaction;
  const shouldShowAutomationActiveMsg =
    !shouldShowTransferInitMsg && status === "active";

  return (
    <div className="automation__container cards_block_container">
      <div className="automations__cardsDetails">
        <button
          className="profile__edit_save dashboard_edit_button"
          onClick={editAutomationHandler}
        >
          Edit
        </button>
      </div>
      <div className="connectionButton__holder">
        <div className="automations__checkingCards">
          <AccountCard
            active={true}
            item={checkingAccount}
            type="Checking account"
            reconnectionFlow={true}
            showOnlyDisconnectedIcon={true}
            disconnected={
              checkingAccount.status === "reconnection_need" ? true : false
            }
            selectHandler={() => {}}
            editFlow={false}
          />
          <div className="automation__fixedValue">
            <p className="automation__fixedValue-title">Optimal Balance</p>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            }).format(+fixedValue)}
          </div>
        </div>

        <div className="arrows__icon">
          {[checkingAccount.status, savingAccount.status].includes(
            "reconnection_need",
          ) ? (
            <img src={arrows_red} alt="Arrows Icon" />
          ) : (
            <img src={arrows} alt="Arrows Icon" />
          )}
        </div>

        <div className="automations__savingsCards">
          <AccountCard
            active={true}
            item={savingAccount}
            type="Savings account"
            reconnectionFlow={true}
            showOnlyDisconnectedIcon={true}
            disconnected={
              savingAccount.status === "reconnection_need" ? true : false
            }
            selectHandler={() => {}}
            editFlow={false}
          />
        </div>
      </div>

      {noTransactionInitiated && (
        <div className="transfer__status">
          <p className="transfer__statusTitle ">
            <span>Automation is Active.</span> How does it work?
          </p>
          <div>
            <Tooltip
              title={
                <>
                  The minimal transfer is $250. If your checking balance{" "}
                  <span style={{ fontWeight: "700" }}>exceeds</span> the optimal
                  balance by $250, we'll move any excess to savings. If it{" "}
                  <span style={{ fontWeight: "700" }}>dips below</span> your
                  optimal balance by $250, we'll top it up using your savings.
                </>
              }
              arrow
              enterTouchDelay={0}
              componentsProps={{
                tooltip: {
                  sx: {
                    textAlign: "left",
                    width: "288px",
                  },
                },
              }}
            >
              <img src={tooltip_info_icon} alt="Info Icon" />
            </Tooltip>
          </div>
        </div>
      )}
      {showInitedTransaction && (
        <p className="transfer__statusTitle-pending text__content">
          {`We initiated a transfer of $${transactionAmount} to ${to} account.`}
        </p>
      )}
      {shouldShowAutomationPausedMsg && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            className="transfer__statusTitle-paused text__content"
            style={{ marginTop: "24px" }}
          >
            Automation paused due to disconnected bank accounts.
          </p>

          <PlaidAuth accessToken={accessToken} reloadPage={reloadPage}>
            <Button
              type={"white-btn"}
              onClick={() => {
                mixpanel("Clicked_Reconnect_Now", {
                  "Origination point": "automation",
                });
              }}
              style={{
                color: "var(--error)",
                backgroundColor: "var(--secondary-black-color)",
                border: "1px solid var(--error)",
                marginTop: "24px",
                width: "195px",
              }}
            >
              Reconnect Now
            </Button>
          </PlaidAuth>
        </div>
      )}
    </div>
  );
};

export default CardsBlock;
