import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepWizardChildProps } from "react-step-wizard";
import { RootState } from "../../../redux/store";
import {
  changeBirthday,
  changeBirthdayDay,
  changeBirthdayMonth,
  changeBirthdayYear,
} from "../../../redux/sliceAuth";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import Button from "../../../elements/Button/Button";
// @ts-ignore

import "./signUpDateBirth.css";
import notification from "../../../elements/ToastNotifications/ToastNotifications";
import { getAuth } from "firebase/auth";
import { useUserEditMutation } from "../../../api/apiAuth";
import Wrapper from "../../../elements/Wrapper";
import DateBirthField from "./DateBirthField";
import dayjs, { Dayjs } from "dayjs";
import { useMixpanel } from "../../../helpers/mixpanel";
import { useNavigate } from "react-router-dom";

const SignUpDateBirth: React.FC<Partial<StepWizardChildProps>> = ({
  currentStep,
  nextStep,
  previousStep,
}) => {
  const authInfo: any = useSelector<RootState>((state) => state.auth);
  const defaultDateValue = authInfo.birthday ? dayjs(authInfo.birthday) : null;
  const [date, setDate] = useState<Dayjs | null>(defaultDateValue);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const auth = getAuth();
  const [editUser, editUserResult] = useUserEditMutation();
  const resetErrorOnChange = () => {
    setError(false);
    setErrorMsg("");
  };
  const navigate = useNavigate();

  const onNextHandler = () => {
    const validationObject = getValidationObject(date);
    setError(!validationObject.valid);
    setErrorMsg(validationObject.msg);
    if (validationObject.valid) {
      const dateF = dayjs(date).format("MM/DD/YYYY");
      const [birthdayMonth, birthdayDay, birthdayYear] = dateF.split("/");
      const birthdayFormat = new Date(
        Number(birthdayYear),
        Number(birthdayMonth) - 1,
        Number(birthdayDay),
      ).toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      dispatch(changeBirthday(birthdayFormat));
      dispatch(changeBirthdayDay(Number(birthdayDay)));
      dispatch(changeBirthdayMonth(Number(birthdayMonth)));
      dispatch(changeBirthdayYear(Number(birthdayYear)));

      auth.currentUser
        ?.getIdToken()
        .then(async (tkn) => {
          const body = {
            birthday: birthdayFormat,
          };
          await editUser({ tkn, body }).then((res: any) => {
            if (res.error) {
              console.log("Error in editUser SignUpDateBirth.tsx: ", res.error);
            } else {
              // nextStep?.();
              navigate("/demo-savings-address");
              mixpanel("KYC_added_birth_date", { DOB: birthdayFormat }, tkn);
            }
          });
        })
        .catch((error) => {
          console.log("Error in getIdToken SignUpDateBirth: ", error);
        });
    }
  };

  const onBackHandler = () => {
    // previousStep?.();
    navigate("/demo-create-automation-savings-module");
  };

  // if (currentStep !== 7) {
  //   return null;
  // }

  const getValidationObject = (value: any) => {
    const validationObject = {
      valid: false,
      msg: "Please enter your date of birth.",
    };
    if (!value) return validationObject;
    const birthDate = new Date(value);
    const currentDate = new Date();
    const birthYear = birthDate.getFullYear();
    const birthMonth = birthDate.getMonth();
    const birthDay = birthDate.getDate();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    let age = currentYear - birthYear;
    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDay < birthDay)
    ) {
      age--;
    }
    if (age < 18) {
      validationObject.msg = "You must be at least 18 years old to use Cache.";
    } else if (birthYear <= 1900 || !dayjs(value).isValid()) {
      validationObject.msg = "Invalid date of birth. Please try again.";
    } else {
      validationObject.valid = true;
      validationObject.msg = "";
    }
    return validationObject;
  };

  return (
    <Wrapper>
      <SignupNavbar step={7} handler={onBackHandler} showBackButton={true} />
      <div className={"page__infoContainer"}>
        <div className="page__title smaller-size">
          Just a few more details, {authInfo.firstName}.<br />
          Please add your date of birth.
        </div>

        <div className="dateBirth__input__holder">
          <div className={`customInput__holder`}>
            <DateBirthField
              fullWidth
              variant="filled"
              name="dateOfBirth"
              label="Date of birth"
              onChange={(value: any) => {
                setDate(value);
                resetErrorOnChange();
              }}
              value={date}
              error={error}
              placeholder="Date of birth"
            />
          </div>
        </div>
        {errorMsg ? (
          <div className="info__subtitle error__msg error__message">
            {errorMsg}
          </div>
        ) : (
          <>
            <div className="info__subtitle">
              We are required by law to ask these questions.
            </div>
            <div className="info__subtitle">
              This will help us keep your account secure.
            </div>
          </>
        )}
      </div>
      <div className="button__holder">
        <Button
          onClick={onNextHandler}
          type={"primary-btn"}
          style={{ opacity: 1 }}
          disabled={editUserResult.isLoading}
        >
          Continue
        </Button>
      </div>
      <input type="hidden" id="abandonedOnboardingStage" value={"kyc_dob"} />
    </Wrapper>
  );
};

export default SignUpDateBirth;
