import { useContext } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLazyGetAutomationsDataQuery } from "../../api/apiDashboard";
import Button from "../../elements/Button/Button";
import Loader from "../../elements/Loader/Loader";
import "./demoDashboard.css";
import { AuthContext } from "../../context/AuthContext";
import { useMixpanel } from "../../helpers/mixpanel";
import arrows from "../../assets/oneDirectionArrows.png";
import DemoCreditCardForDashboard from "../../elements/DemoCreditCardForDashboard/DemoCreditCardForDashboard";
import DemoAccountCard from "../../elements/DemoAccountCard/DemoAccountCard";
import DemoTransfersTable from "../../elements/DemoTransferTable/DemoTransferTable";
import {
  selectBankAccount,
  selectLiabilityAutomation,
} from "../../redux/sliceCreditCards";
import { RootState } from "../../redux/store";
import HeaderMenu from "../../elements/HeaderMenu/HeaderMenu";

function DemoDashboard() {
  const context = useContext(AuthContext);
  const [getAutomations, getAutomationsResult] =
    useLazyGetAutomationsDataQuery();
  const mixpanel = useMixpanel();
  const LiabilityAutomation = useSelector(selectLiabilityAutomation);
  const bankAccount = useSelector(selectBankAccount);

  const userDefinedMaxPayment: any = useSelector<RootState>(
    (state) => state.creditCards.userDefinedMaxPayment,
  );
  const optimizationMethod = useSelector<RootState>(
    (state) => state.creditCards.optimizationMethod,
  );

  function toTitleCase(str: any) {
    return str?.replace(
      /^\w\S*/,
      (text: any) =>
        text?.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
    );
  }

  return (
    <div>
      <HeaderMenu />
      <div className="parent_container">
        <div className="demo_dashboard_page_info_container">
          <div
            className="active_automations_row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="page__title">Active Automations</div>
            <div className="add_automation_mobile_button">
              <Button type={"primary-btn"} style={{ opacity: 1 }}>
                + Add Automation
              </Button>
            </div>
          </div>
          <div className="credit_automation_block">
            <div className="edit_demo_bttn">Edit</div>
            <DemoAccountCard
              cacheType={toTitleCase(bankAccount?.cacheType)}
              active={true}
              item={bankAccount}
              className="demo_account_card"
            />
            <div className="parent_max_and_opt_block">
              <div className="max_and_optimiz_block">
                <div className="titles_of_max_and_opt">
                  <div className="max_opt_title">Max Amount</div>
                  <div className="max_opt_title">Optimization Goal</div>
                </div>
                <div className="val_of_max_and_opt">
                  <div className="max_amount">$ {userDefinedMaxPayment}</div>
                  <div className="opt_block">
                    <div className="optimiz_method">
                      {toTitleCase(optimizationMethod)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="max_and_optimiz_block_tablet">
              <div className="titles_of_max_and_opt_tablet">
                <div className="max_opt_title_mobile">Max Amount</div>
                <div className="max_amount">$ {userDefinedMaxPayment}</div>
              </div>
              <div className="val_of_max_and_opt_mobile">
                <div className="max_opt_title_mobile">Optimization Goal</div>
                <div className="opt_block_mobile">
                  <div className="optimiz_method_mobile">
                    {toTitleCase(optimizationMethod)}
                  </div>
                </div>
              </div>
            </div>
            <div className="arrows_icon_container_demodashboard">
              <img src={arrows} alt="arrows icon" />
            </div>
            <div className="parent_max_and_opt_block">
              <div className="max_and_optimiz_block_mobile">
                <div className="titles_of_max_and_opt_mobile">
                  <div className="max_opt_title_mobile">Max Amount</div>
                  <div className="max_amount">$ {userDefinedMaxPayment}</div>
                </div>
                <div className="val_of_max_and_opt_mobile">
                  <div className="max_opt_title_mobile">Optimization Goal</div>
                  <div className="opt_block_mobile">
                    <div className="optimiz_method_mobile">
                      {toTitleCase(optimizationMethod)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {LiabilityAutomation?.creditCards.map((card, index) => {
              const currentCard = {
                id: card.id,
                mask: card.creditCardNumberMasked,
                institution: {
                  name: card.bank,
                },
                isUsedInAutomation: false,
                balance: card.statementBalance,
              };
              const reason = LiabilityAutomation.optimizedPayments.cards.filter(
                (c) => c.cardID === card.id,
              )[0]?.reason;
              const formattedDueDate = new Date(
                card.dueDate,
              ).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                timeZone: "UTC",
              });
              return (
                <DemoCreditCardForDashboard
                  key={card.id}
                  account={currentCard}
                  balance={card.statementBalance}
                  minAmountDue={card.minimumPaymentAmount}
                  apr={card.apr}
                  dueDate={formattedDueDate}
                  utilizationRate={card.creditUtilization}
                  footerText={reason}
                  bank={card.bank}
                />
              );
            })}
          </div>

          <div className="add_automation_mobile">
            <Button type={"primary-btn"} style={{ opacity: 1 }}>
              + Add Automation
            </Button>
          </div>

          <div id="latest-transfers">
            <DemoTransfersTable
              transfersData={LiabilityAutomation?.optimizedPayments.cards}
            />
          </div>
          <div className="viewMoreBlock">
            <Link to="" className="viewMore">
              View More
            </Link>
          </div>
        </div>
      </div>
      <Loader loading={getAutomationsResult.isFetching} />
    </div>
  );
}

export default DemoDashboard;
