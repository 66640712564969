import { useContext, useEffect, useRef, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import {
  useLazyGetAutomationsQuery,
  useLazyGetUserAccountsQuery,
  useModifyAutomationMutation,
  usePostAutomationMutation,
} from "../../../api/apiPlaid";
import { useDispatch, useSelector } from "react-redux";
import {
  setSavingsAccountList,
  setCheckingAccountList,
} from "../../../redux/sliceAccounts";
import {
  setAccountsConnected,
  setBalanceMaxValue,
  setCheckingAcc,
  setSavingsAcc,
  setShowOptimalBalance,
  setSignUpAutomationId,
} from "../../../redux/sliceUserAutomation";
import { RootState } from "../../../redux/store";
import PlaidAuth from "../../Plaid/PlaidAuth";
// @ts-ignore
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import Button from "../../../elements/Button/Button";
import arrows from "../../../assets/arrows_vertical.png";
import "./demoCreateAutomationSavingsModule.css";
import Wrapper from "../../../elements/Wrapper";
import { AuthContext } from "../../../context/AuthContext";
import { Timeout } from "react-number-format/types/types";
import { useMixpanel } from "../../../helpers/mixpanel";
import { AccountType } from "../../../helpers/types";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { specialBankInstitutions } from "../../../helpers/specialBankInstitutions";
import { ModalAccountsList } from "../../../elements/Modals/ModalAccountList";
import AutomationSetWrapUp from "../../DemoSignUp/AutomationSetWrapUp/AutomationSetWrapUp";
import DemoConnectionButton from "../../../elements/DemoConnectionButton/DemoConnectionButton";
import DemoAccountCard from "../../../elements/DemoAccountCard/DemoAccountCard";
import DemoAccountLoading from "../../../elements/DemoAccountLoading/DemoAccountLoading";
import DemoMaximalInput from "../../../elements/DemoMaximalInput/DemoMaximalInput";
import {
  selectMaximalAmount,
  setMaximalAmount,
} from "../../../redux/sliceCreditCards";

const DemoCreateAutomationSavingsModule: React.FC<
  Partial<StepWizardChildProps>
> = () => {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const [getAccountsList, accountsListResult] = useLazyGetUserAccountsQuery();
  const [createAutomation, createAutomationResult] =
    usePostAutomationMutation();
  const [modifyAutomation, modifyResult] = useModifyAutomationMutation();
  const [getAutomations, getAutomationsResult] = useLazyGetAutomationsQuery();

  const [isCheckingWrong, setIsCheckingWrong] = useState<any>(null);
  const [isSavingsWrong, setIsSavingsWrong] = useState<any>(null);

  const [showCheckingAccountsModal, setShowCheckingAccountsModal] =
    useState(false);
  const [showSavingsAccountsModal, setShowSavingsAccountsModal] =
    useState(false);

  const accountsConnected: any = useSelector<RootState>(
    (state) => state.userAutomation.accountsConnected,
  );

  const arrayOfStrangerAccountsErrors: any = useSelector<RootState>(
    (state) => state.userAutomation.arrayOfStrangerAccountsErrors,
  );
  const [optimalAmountSaved, setOptimalAmountSaved] = useState(false);

  const [isPlaidAuthLoading, setIsPlaidAuthLoading] = useState(false);
  const [isPlaidAuthOpen, setIsPlaidAuthOpen] = useState(false);
  const [isCheckingBtnActive, setIsCheckingBtnActive] = useState(false);
  const [isSavingsBtnActive, setIsSavingsBtnActive] = useState(false);
  const [continueButtonisClicked, setContinueButtonisClicked] = useState(false);
  const [backButtonisClicked, setBackButtonisClicked] = useState(false);
  const [typeAcc, setTypeAcc] = useState("");

  const checkingAccounts: any = useSelector<RootState>(
    (state) => state.accounts.checkingAccountList,
  );
  const savingsAccounts: any = useSelector<RootState>(
    (state) => state.accounts.savingsAccountList,
  );
  const signUpAutomationId = useSelector<RootState>(
    (state) => state.userAutomation.signUpAutomationId,
  );
  const [value, setValue] = useState<null | number>(null);
  const [accountLoadMsg, setAccountLoadMsg] = useState("");
  const [accountErrorType, setAccountErrorType] = useState("");
  const [accountType, setAccountType] = useState({
    type: AccountType.CHECKING,
    clicked: false,
  });
  const [showBackButton, setShowBackButton] = useState(true);

  const [isAutomationSavingToDB, setIsAutomationSavingToDB] = useState(false);
  const [isAutomationDraftSavingToDB, setIsAutomationDraftSavingToDB] =
    useState(false);
  const [isSavedAutomationRecovered, setIsSavedAutomationRecovered] =
    useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const saveAutomationTimerIdRef = useRef<Timeout | null>(null);
  const savingsCardRef = useRef(null);
  const [accountError, setAccountError] = useState(false);
  const [accountErrorMessage, setAccountErrorMessage] = useState("");
  const accountLoadErrorCallback = (errorMsg: string, type: string) => {
    setAccountLoadMsg(errorMsg);
    setAccountErrorType(type);
    setIsPlaidAuthOpen(false);
  };

  const checkingAccount: any = useSelector<RootState>(
    (state) => state?.userAutomation?.checkingAccount || {},
  );
  const savingsAccount: any = useSelector<RootState>(
    (state) => state?.userAutomation?.savingsAccount || {},
  );
  const showOptimalBalance: any = useSelector<RootState>(
    (state) => state?.userAutomation?.showOptimalBalance,
  );
  const userData: any = useSelector<RootState>((state) => state.auth);
  const [showAutomationWrapUp, setShowAutomationWrapUp] = useState(false);
  const [showFixedBalanceSummary, setShowFixedBalanceSummary] = useState(false);
  const [strangerSavingsError, setStrangerSavingsError] = useState(false);
  const [strangerCheckingError, setStrangerCheckingError] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const checkingAvailableBalance = parseFloat(
    Number(checkingAccount?.availableBalance).toFixed(2),
  );
  const savingsAvailableBalance =
    typeof savingsAccount === "object" && savingsAccount?.availableBalance
      ? parseFloat(Number(savingsAccount.availableBalance).toFixed(2))
      : 0;

  const [connectAccountsButtonClicked, setConnectAccountsButtonClicked] =
    useState(false);
  const [maximalAmountError, setMaximalAmountError] = useState(false);
  const [shouldShowChangeAccount, setShouldShowChangeAccount] = useState(false);
  const formatAmount = (amount: string) => {
    const number = parseFloat(amount.replace(/,/g, ""));
    return isNaN(number)
      ? amount
      : number.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
  };
  const recommendedMaximalValue = checkingAccount?.availableBalance
    ? formatAmount((checkingAccount.availableBalance * 0.6).toString())
    : "0.00";
  const maximalAmount = useSelector(selectMaximalAmount);
  const navigate = useNavigate();
  useEffect(() => {
    if (checkingAccount && !maximalAmount) {
      dispatch(setMaximalAmount(checkingAccount?.availableBalance * 0.6));
    }
  }, [checkingAccount]);

  useEffect(() => {
    if (showAutomationWrapUp) {
      const timer = setTimeout(() => {
        navigate("/demo-savings-date-birth");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAutomationWrapUp]);

  useEffect(() => {
    if (!checkingAccount && !savingsAccount) {
      setShowBackButton(false);
    }
    if (showOptimalBalance) {
      setShowBackButton(true);
    }
    if (!showOptimalBalance) {
      setShowBackButton(false);
    }
  }, [!showOptimalBalance]);

  useEffect(() => {
    arrayOfStrangerAccountsErrors?.map((errorType: any) => {
      if (errorType === "STRANGER_ACCOUNT_CHECKING" && isCheckingCardClicked) {
        setStrangerCheckingError(true);
      }
      if (errorType === "STRANGER_ACCOUNT_SAVINGS" && isSavingCardClicked) {
        setStrangerSavingsError(true);
      }
      if (
        errorType === "STRANGER_ACCOUNT_CHECKING" &&
        connectAccountsButtonClicked
      ) {
        setStrangerCheckingError(true);
      }
      if (
        errorType === "STRANGER_ACCOUNT_SAVINGS" &&
        connectAccountsButtonClicked &&
        arrayOfStrangerAccountsErrors.length === 1
      ) {
        setStrangerSavingsError(true);
      }
    });
  }, [arrayOfStrangerAccountsErrors]);

  const renderBalanceMessage = () => {
    if (!maximalAmount) {
      return;
    }
    const maximalAmountNum = parseFloat(maximalAmount?.toString() || "0") || 0;

    const sum = maximalAmountNum
      ? Number((maximalAmountNum - checkingAvailableBalance).toFixed(2))
      : 0;
    let transferSum = 0;
    const transferAmount = maximalAmountNum - checkingAvailableBalance;

    if (savingsAccount && savingsAccount.availableBalance !== undefined) {
      if (savingsAccount.availableBalance > transferAmount) {
        transferSum = transferAmount || 0;
      } else {
        transferSum = savingsAccount.availableBalance;
      }
    } else {
      transferSum = 0;
    }

    const formatNumber = (num: any) =>
      num.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    if (
      maximalAmountNum &&
      checkingAvailableBalance &&
      maximalAmountNum < checkingAvailableBalance
    ) {
      return (
        <div
          className={classNames(
            "fixedBalanceSummary",
            showFixedBalanceSummary && "nextButton_after_fixed_sum",
          )}
        >
          The optimal balance is{" "}
          <span style={{ color: "#39848A" }}>
            ${formatNumber(checkingAvailableBalance - maximalAmountNum)}
            &nbsp;lower
          </span>{" "}
          than your checking account balance. This will result in a transfer of{" "}
          <span style={{ color: "#39848A" }}>
            ${formatNumber(Math.abs(Math.min(sum, savingsAvailableBalance)))}
          </span>{" "}
          from your <span style={{ color: "#39848A" }}>checking account</span>{" "}
          to your<span style={{ color: "#39848A" }}> savings account.</span>
        </div>
      );
    }
    if (
      maximalAmountNum &&
      checkingAvailableBalance &&
      maximalAmountNum > checkingAvailableBalance
    ) {
      return (
        <div
          className={classNames(
            "fixedBalanceSummary",
            showFixedBalanceSummary && "nextButton_after_fixed_sum",
          )}
        >
          The optimal balance is{" "}
          <span style={{ color: "#39848A" }}>
            ${formatNumber(maximalAmountNum - checkingAvailableBalance)}
            &nbsp;higher
          </span>{" "}
          than your checking account balance. This will result in a transfer of{" "}
          <span style={{ color: "#39848A" }}>
            ${formatNumber(transferSum)}
          </span>{" "}
          from your <span style={{ color: "#39848A" }}>savings account</span> to
          your<span style={{ color: "#39848A" }}> checking account.</span>
        </div>
      );
    } else if (
      maximalAmountNum &&
      checkingAvailableBalance &&
      maximalAmountNum < checkingAvailableBalance
    ) {
      return (
        <div
          className={classNames(
            "fixedBalanceSummary",
            showFixedBalanceSummary && "nextButton_after_fixed_sum",
          )}
        >
          The optimal balance that you entered is{" "}
          <span style={{ color: "#39848A" }}>
            ${formatNumber(checkingAvailableBalance - maximalAmountNum)}
          </span>{" "}
          lower than your checking account balance. This will result in a
          transfer of{" "}
          <span style={{ color: "#39848A" }}>
            ${formatNumber(checkingAvailableBalance - maximalAmountNum)}{" "}
          </span>{" "}
          from your <span style={{ color: "#39848A" }}>checking account </span>
          to your<span style={{ color: "#39848A" }}> savings account.</span>
        </div>
      );
    } else {
      return (
        <div
          className={classNames(
            "fixedBalanceSummary",
            showFixedBalanceSummary && "nextButton_after_fixed_sum",
          )}
        >
          The optimal balance that you entered is equal to your checking account
          balance. No transfer will be initiated at this time.
        </div>
      );
    }
  };

  const recoverSavedAutomation = async () => {
    try {
      const { user } = context;
      if (!user) {
        return;
      }
      const idToken = await user.getIdToken();
      const automationsResponse = await getAutomations({ idToken });

      const automation = automationsResponse?.data?.data[0];
      if (automation) {
        const savedCheckingAccount = automation?.checkingAccount;
        const savedSavingsAccount = automation?.savingAccount;
        const savedFixedValue = automation?.fixedValue;
        const savedAutomationID = automation?.id;

        if (savedAutomationID) {
          dispatch(setSignUpAutomationId(savedAutomationID));
        }

        if (
          savedCheckingAccount &&
          Object.keys(savedCheckingAccount).length !== 0
        ) {
          dispatch(setCheckingAcc(savedCheckingAccount));
        }

        if (
          savedSavingsAccount &&
          Object.keys(savedSavingsAccount).length !== 0
        ) {
          dispatch(setSavingsAcc(savedSavingsAccount));
        }

        if (typeof savedFixedValue === "number") {
          dispatch(setBalanceMaxValue(savedFixedValue));
          setValue(Number(automation?.fixedValue));
        }
      }

      setIsSavedAutomationRecovered(true);
    } catch (error) {
      console.log("Error in recoverSavedAutomation, AddAutomation.tsx");
    }
  };

  const getAccounts = async (accountToUpdate?: {
    [field: string]: any | null;
  }) => {
    try {
      const { user } = context;
      if (!user) {
        return;
      } else {
        await user.getIdToken().then(async (idToken: string) => {
          const result = await getAccountsList({ idToken });
          const accountsResult = result?.data?.data;
          if (accountsResult && accountToUpdate) {
            const extendedAccount = accountsResult.find(
              ({ plaidAccountId }: { plaidAccountId: string }) =>
                plaidAccountId === accountToUpdate.plaidAccountId,
            );
            if (
              extendedAccount?.subtype === AccountType.CHECKING &&
              extendedAccount.institution.id !==
                specialBankInstitutions?.wealthfront
            ) {
              dispatch(setCheckingAcc(extendedAccount));
            } else if (
              extendedAccount?.subtype === "savings" ||
              (extendedAccount?.subtype === AccountType.CHECKING &&
                extendedAccount.institution.id ===
                  specialBankInstitutions?.wealthfront)
            ) {
              dispatch(setSavingsAcc(extendedAccount));
            }
          }
        });
      }
    } catch (error) {
      console.log("error in getAccounts AddAutomation.tsx: ", error);
    }
  };

  const handleModalOpen = (type: string) => {
    document.body.classList.remove("reset-mui-overflow");
    if (type === AccountType.CHECKING) {
      setShowCheckingAccountsModal(true);
      mixpanel("Opened_Checking_List", {});
    } else if (type === AccountType.SAVING) {
      setShowSavingsAccountsModal(true);
      mixpanel("Opened_Savings_List", {});
    }
    setAccountLoadMsg("");
    setAccountErrorType("");
  };

  const handleModalClose = (type: string) => {
    document.body.classList.add("reset-mui-overflow");
    if (type === AccountType.CHECKING) {
      setShowCheckingAccountsModal(false);
    } else if (type === AccountType.SAVING) {
      setShowSavingsAccountsModal(false);
    }
  };

  const applyDefaultAccounts = async (accounts: any[]) => {
    try {
      if (accounts.length === 0) {
        return null;
      }

      if (isSavingCardClicked) {
        if (
          (accounts[0]?.subtype === AccountType.CHECKING &&
            accounts[1]?.subtype === AccountType.SAVING) ||
          (accounts[0]?.subtype === AccountType.SAVING &&
            accounts[1]?.subtype === AccountType.CHECKING)
        ) {
          handleModalClose(AccountType.SAVING);
          accounts.forEach(({ subtype }) => {
            if (
              isCheckingCardClicked &&
              subtype === AccountType.SAVING &&
              subtype === !AccountType.CHECKING &&
              accounts?.length === 1
            ) {
              setIsCheckingWrong(true);
              return;
            }
          });
          const connectedSavingsAccounts = accounts?.filter(
            ({ subtype, institution }) =>
              subtype === "savings" ||
              (subtype === AccountType.CHECKING &&
                institution.id === specialBankInstitutions?.wealthfront),
          );
          const connectedCheckingAccounts = accounts?.filter(
            ({ subtype, institution }) =>
              subtype === AccountType.CHECKING &&
              institution.id !== specialBankInstitutions?.wealthfront,
          );

          if (connectedSavingsAccounts.length > 1) {
            setSavingsAcc(null);
            await getAccounts();
            handleModalOpen(AccountType.SAVING);
            return;
          }

          if (
            connectedSavingsAccounts.length === 1 &&
            connectedCheckingAccounts.length > 1
          ) {
            dispatch(setSavingsAcc(connectedSavingsAccounts[0]));
            handleModalOpen(AccountType.CHECKING);
          }

          if (connectedSavingsAccounts.length === 1) {
            if (isCheckingCardClicked) {
              await getAccounts();
              return;
            }

            const defaultSavingsAccount = connectedSavingsAccounts[0];
            dispatch(setSavingsAcc(defaultSavingsAccount));
            mixpanel("Connected_savings_account", {
              Banks: defaultSavingsAccount,
            });
            getAccounts(defaultSavingsAccount);
          }
          if (connectedSavingsAccounts.length === 0) {
            getAccounts();
            return;
          }
        }
      }
      if (
        accounts[0]?.subtype === AccountType.CHECKING ||
        accounts[1]?.subtype === AccountType.CHECKING
      ) {
        handleModalClose(AccountType.CHECKING);
        accounts.forEach(({ subtype, institution }) => {
          if (
            isSavingCardClicked &&
            subtype === AccountType.CHECKING &&
            subtype === !AccountType.SAVING &&
            accounts.length === 1
          ) {
            setIsSavingsWrong(true);
            return;
          }
          if (
            isSavingCardClicked &&
            subtype === AccountType.CHECKING &&
            subtype === !AccountType.SAVING &&
            accounts.length === 1
          ) {
            setIsSavingsWrong(true);
            return;
          }
          if (
            isSavingCardClicked &&
            subtype === AccountType.CHECKING &&
            subtype === !AccountType.SAVING &&
            accounts.length === 1
          ) {
            setIsSavingsWrong(true);
            return;
          }
          if (
            subtype === AccountType.CHECKING &&
            institution.id === specialBankInstitutions?.wealthfront
          ) {
            if (
              accountType.type == AccountType.CHECKING &&
              accountType.clicked
            ) {
              setIsCheckingWrong(true);
            }
          }
        });

        const connectedCheckingAccounts = accounts?.filter(
          ({ subtype, institution }) =>
            subtype === AccountType.CHECKING &&
            institution.id !== specialBankInstitutions?.wealthfront,
        );
        const connectedSavingsAccounts = accounts?.filter(
          ({ subtype, institution }) =>
            subtype === "savings" ||
            (subtype === AccountType.CHECKING &&
              institution.id === specialBankInstitutions?.wealthfront),
        );

        if (connectedCheckingAccounts.length > 1) {
          setCheckingAcc(null);
          await getAccounts();
          handleModalOpen(AccountType.CHECKING);
          return;
        }
        if (
          connectedCheckingAccounts.length === 1 &&
          connectedSavingsAccounts.length > 1
        ) {
          dispatch(setCheckingAcc(connectedCheckingAccounts[0]));
          handleModalOpen(AccountType.SAVING);
        }
        if (isSavingCardClicked) {
          await getAccounts();
          return;
        }
        if (connectedCheckingAccounts.length === 1) {
          const defaultCheckingAccount = connectedCheckingAccounts[0];
          if (
            defaultCheckingAccount.institution.id !==
            specialBankInstitutions?.wealthfront
          ) {
            dispatch(setCheckingAcc(defaultCheckingAccount));
            mixpanel("Connected_checking_account", {
              Banks: defaultCheckingAccount,
            });
            getAccounts(defaultCheckingAccount);
          }
        }

        if (connectedCheckingAccounts.length === 0) {
          getAccounts();
          return;
        }
      }
      if (
        accounts[0]?.subtype === AccountType.SAVING ||
        accounts[1]?.subtype === AccountType.SAVING ||
        (accounts[0].subtype === AccountType.CHECKING &&
          accounts[0].institution.id === specialBankInstitutions?.wealthfront)
      ) {
        handleModalClose(AccountType.SAVING);
        accounts.forEach(({ subtype }) => {
          if (
            isCheckingCardClicked &&
            subtype === AccountType.SAVING &&
            subtype === !AccountType.CHECKING &&
            accounts?.length === 1
          ) {
            setIsCheckingWrong(true);
            return;
          }
        });
        const connectedSavingsAccounts = accounts?.filter(
          ({ subtype, institution }) =>
            subtype === "savings" ||
            (subtype === AccountType.CHECKING &&
              institution.id === specialBankInstitutions?.wealthfront),
        );
        const connectedCheckingAccounts = accounts?.filter(
          ({ subtype, institution }) =>
            subtype === AccountType.CHECKING &&
            institution.id !== specialBankInstitutions?.wealthfront,
        );

        if (connectedSavingsAccounts.length > 1) {
          setSavingsAcc(null);
          await getAccounts();
          handleModalOpen(AccountType.SAVING);
          return;
        }
        if (
          connectedSavingsAccounts.length === 1 &&
          connectedCheckingAccounts.length > 1
        ) {
          dispatch(setSavingsAcc(connectedSavingsAccounts[0]));
          handleModalOpen(AccountType.CHECKING);
        }

        if (connectedSavingsAccounts.length === 1) {
          if (isCheckingCardClicked) {
            await getAccounts();
            return;
          }

          const defaultSavingsAccount = connectedSavingsAccounts[0];
          dispatch(setSavingsAcc(defaultSavingsAccount));
          mixpanel("Connected_savings_account", {
            Banks: defaultSavingsAccount,
          });
          getAccounts(defaultSavingsAccount);
        }
        if (connectedSavingsAccounts.length === 0) {
          getAccounts();
          return;
        }
      }
    } catch (error) {
      console.log("Error in applyDefaultAccounts AddAutomation.tsx: ", error);
    }
  };

  const verifyAccountsSubtype = (accounts: any[], institution: any) => {
    if (!Array.isArray(accounts)) {
      return;
    }

    const isAccountTypeMatch = accounts.some((account) => {
      return (
        account?.subtype === typeAcc ||
        (account?.subtype === AccountType.CHECKING &&
          institution?.institution_id === specialBankInstitutions?.wealthfront)
      );
    });

    if (isAccountTypeMatch) {
      return;
    }

    if (typeAcc === AccountType.CHECKING) {
      dispatch(setCheckingAcc(null));
      setCheckingAcc(null);
      setIsCheckingWrong(true);
      setAccountErrorType(AccountType.CHECKING);
    }
    if (typeAcc === "savings") {
      dispatch(setSavingsAcc(null));
      setSavingsAcc(null);
      setIsSavingsWrong(true);
      setAccountErrorType("savings");
    }
  };

  useEffect(() => {
    if (isPlaidAuthLoading) {
      handleModalClose(AccountType.CHECKING);
      handleModalClose(AccountType.SAVING);
    }
  }, [isPlaidAuthLoading]);

  useEffect(() => {
    recoverSavedAutomation();
    getAccounts();
  }, []);

  useEffect(() => {
    if (createAutomationResult?.data?.status === "SUCCESS") {
      const automationId = createAutomationResult.data.automationId;
      dispatch(setSignUpAutomationId(automationId));
    }
  }, [createAutomationResult]);

  useEffect(() => {
    if (accountsListResult.data) {
      let checkingAccounts: Array<string> = [];
      let savingsAccounts: Array<string> = [];

      accountsListResult?.data?.data.forEach((item: any) => {
        if (
          item.subtype === "savings" ||
          (item.subtype === AccountType.CHECKING &&
            item.institution.id === specialBankInstitutions?.wealthfront)
        ) {
          savingsAccounts.push(item);
        } else if (item.subtype === AccountType.CHECKING) {
          checkingAccounts.push(item);
        }
      });
      dispatch(setCheckingAccountList(checkingAccounts));
      dispatch(setSavingsAccountList(savingsAccounts));
    } else {
      return;
    }
  }, [accountsListResult]);

  useEffect(() => {
    if (!checkingAccount || !savingsCardRef.current) {
      return;
    }

    (savingsCardRef.current as any).scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [checkingAccount]);

  const saveAutomationDraftToDB = async () => {
    const automationData = {
      checkingAccountId: checkingAccount?.id ?? null,
      savingAccountId: savingsAccount?.id ?? null,
      fixedValue: maximalAmount ?? null,
      isDraft: true,
    };
    const { user } = context;
    if (!user || isAutomationSavingToDB || !isSavedAutomationRecovered) {
      return;
    }

    await user.getIdToken().then(async (idToken: string) => {
      if (signUpAutomationId) {
        await modifyAutomation({
          body: automationData,
          idToken,
          automationId: signUpAutomationId,
          isOnboarding: true,
        });
      } else {
        await createAutomation({
          body: automationData,
          idToken,
          isOnboarding: true,
        });
      }
    });
  };
  useEffect(() => {
    if (
      checkingAccount &&
      savingsAccount &&
      Object.keys(checkingAccount).length > 0 &&
      Object.keys(savingsAccount).length > 0
    ) {
      setOptimalAmountSaved(true);
      dispatch(setShowOptimalBalance(true));
    }
  }, [checkingAccount, savingsAccount]);

  useEffect(() => {
    const saveAutomationTimerId = saveAutomationTimerIdRef.current;
    if (saveAutomationTimerId) {
      clearTimeout(saveAutomationTimerId);
    }

    setIsAutomationDraftSavingToDB(true);

    saveAutomationDraftToDB().finally(() => {
      setIsAutomationDraftSavingToDB(false);
    });
  }, [checkingAccount?.id, savingsAccount?.id]);

  useEffect(() => {
    const saveAutomationTimerId = saveAutomationTimerIdRef.current;

    if (saveAutomationTimerId) {
      clearTimeout(saveAutomationTimerId);
    }

    const newTimeoutId = setTimeout(() => {
      setIsAutomationDraftSavingToDB(true);
      saveAutomationDraftToDB().finally(() => {
        setIsAutomationDraftSavingToDB(false);
      });
    }, 1500);

    saveAutomationTimerIdRef.current = newTimeoutId;

    return () => {
      if (saveAutomationTimerId) {
        clearTimeout(saveAutomationTimerId);
      }
    };
  }, [value]);

  useEffect(() => {
    if (accountLoadMsg === "STRANGER_ACCOUNT") {
      dispatch(setAccountsConnected(true));
    }
  }, [accountLoadMsg]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [continueButtonisClicked]);

  const confirmAutomationInDB = () => {
    const saveAutomationTimerId = saveAutomationTimerIdRef.current;
    if (saveAutomationTimerId) {
      clearTimeout(saveAutomationTimerId);
    }

    const automationData = {
      checkingAccountId: checkingAccount?.id ?? null,
      savingAccountId: savingsAccount?.id ?? null,
      fixedValue: maximalAmount ? Number(maximalAmount) : null,
      isDraft: false,
    };

    const { user } = context;
    if (!user) {
      return;
    }

    setIsAutomationSavingToDB(true);
    user.getIdToken().then(async (idToken: string) => {
      await modifyAutomation({
        body: automationData,
        idToken,
        automationId: signUpAutomationId,
        isOnboarding: true,
      }).then(() => {
        setIsAutomationSavingToDB(false);
      });
    });
  };
  const onContinue = () => {
    dispatch(setShowOptimalBalance(true));
  };
  const onNextHandler = () => {
    if (isAutomationDraftSavingToDB || !signUpAutomationId) {
      return;
    }
    if (!checkingAccount && !savingsAccount) {
      setAccountError(true);
      setAccountErrorMessage("Please connect your accounts");
    }

    if (optimalAmountSaved) {
      if (!maximalAmount || maximalAmount === 0) {
        setMaximalAmountError(true);
        return;
      }

      confirmAutomationInDB();
      setShowAutomationWrapUp(true);
    }
  };

  const SelectAccText = (
    <>
      <div className="page__title">
        {" "}
        {`Welcome, ${userData?.firstName}! Let’s set up your automation.`}
      </div>
      <div className="page__subtitle subtitle_block">
        {showOptimalBalance
          ? "Next, please approve or set the optimal balance that you need to maintain in your checking account to cover upcoming expenses. Excess funds are automatically transferred to your savings to maximize interest, and if you’re low on funds, money is transferred back from your savings."
          : "First, please choose the bank accounts that you want to include in your automation. Cache will automate transfers between these accounts based on the optimal amount of money that should remain in your checking account. Excess funds are automatically transferred to your savings to maximize interest, and if you’re low on funds, money is transferred back from your savings."}
      </div>
    </>
  );

  const onPlaidAuthSuccess = async (justConnectedAccounts: any[]) => {
    dispatch(setAccountsConnected(true));
    applyDefaultAccounts(justConnectedAccounts);
    setIsPlaidAuthOpen(false);
  };
  const onPlaidAuthExit = () => {
    setIsPlaidAuthOpen(false);
  };

  const isAccountListResultLoading = accountsListResult.isFetching;
  const hiddenFieldValue =
    !checkingAccount && !savingsAccount
      ? "checkingConnect"
      : checkingAccount && !savingsAccount
      ? "savingsConnect"
      : "fixedBalance";

  const isCheckingCardClicked =
    accountType.type === AccountType.CHECKING && accountType.clicked;
  const isSavingCardClicked =
    accountType.type === AccountType.SAVING && accountType.clicked;

  const onBackHandler = () => {
    setBackButtonisClicked(true);
    if (showOptimalBalance) {
      dispatch(setShowOptimalBalance(false));
    }
    if (!maximalAmount) {
      if (checkingAccount && savingsAccount) {
        dispatch(setCheckingAcc(null));
        dispatch(setSavingsAcc(null));
      }
      if (checkingAccount) {
        dispatch(setCheckingAcc(null));
      }
      if (savingsAccount) {
        dispatch(setSavingsAcc(null));
      }
    }
  };

  return (
    <>
      {showAutomationWrapUp ? (
        <AutomationSetWrapUp />
      ) : (
        <Wrapper>
          <SignupNavbar
            showBackButton={showBackButton}
            step={5}
            handler={onBackHandler}
          />
          <div className="page__infoContainer">
            {SelectAccText}
            {showOptimalBalance && (
              <>
                <div className="automation__container automation_block">
                  <div className="demo_connectionButton__holder">
                    <div className="automations__checkingCards">
                      {isAccountListResultLoading || isPlaidAuthLoading ? (
                        <DemoAccountLoading />
                      ) : Object.keys(checkingAccount).length > 0 ? (
                        <DemoAccountCard
                          changeAccount={() => {
                            setStrangerCheckingError(false);
                            setStrangerSavingsError(false);
                            setIsSavingsWrong(false);
                            setConnectAccountsButtonClicked(false);
                            setAccountLoadMsg("");
                            setAccountType({
                              type: AccountType.CHECKING,
                              clicked: true,
                            });
                            setAccountErrorType("");
                            setIsCheckingWrong(false);
                            setTypeAcc(AccountType.CHECKING);
                            if (checkingAccounts.length === 0) {
                              setIsPlaidAuthOpen(true);
                            } else {
                              handleModalOpen(AccountType.CHECKING);
                            }
                          }}
                          showChangeAccountButton={shouldShowChangeAccount}
                          cacheType="Checking"
                          isSavingsModule={true}
                          active={isCheckingBtnActive}
                          errorCard={
                            accountErrorType === AccountType.CHECKING &&
                            isCheckingCardClicked
                          }
                          item={checkingAccount}
                          selectHandler={() => {
                            setAccountType({
                              type: AccountType.CHECKING,
                              clicked: true,
                            });
                            handleModalOpen(AccountType.CHECKING);
                            setTypeAcc(AccountType.CHECKING);
                          }}
                        />
                      ) : (
                        <DemoConnectionButton
                          className={
                            accountLoadMsg === "STRANGER_ACCOUNT" &&
                            accountErrorType === AccountType.CHECKING &&
                            connectAccountsButtonClicked &&
                            "error_border"
                          }
                          accountType={"Checking Account"}
                          isActive={true}
                          errorCard={
                            accountErrorType === AccountType.CHECKING &&
                            isCheckingCardClicked
                          }
                          isWrongChoice={isCheckingWrong}
                          onClickHandler={() => {
                            setStrangerCheckingError(false);
                            setStrangerSavingsError(false);
                            setIsSavingsWrong(false);
                            setConnectAccountsButtonClicked(false);
                            setAccountLoadMsg("");
                            setAccountType({
                              type: AccountType.CHECKING,
                              clicked: true,
                            });
                            setAccountErrorType("");
                            setIsCheckingWrong(false);
                            setTypeAcc(AccountType.CHECKING);
                            if (checkingAccounts.length === 0) {
                              setIsPlaidAuthOpen(true);
                            } else {
                              handleModalOpen(AccountType.CHECKING);
                            }
                          }}
                        />
                      )}
                    </div>

                    <div
                      className={classNames("arrows__icon", "demo_arrows_Icon")}
                    >
                      <img src={arrows} alt="arrows icon" />
                    </div>

                    <div
                      className="automations__savingsCards"
                      ref={savingsCardRef}
                    >
                      {isAccountListResultLoading || isPlaidAuthLoading ? (
                        <DemoAccountLoading />
                      ) : Object.keys(savingsAccount).length > 0 ? (
                        <>
                          <DemoAccountCard
                            changeAccount={() => {
                              setStrangerCheckingError(false);
                              setStrangerSavingsError(false);
                              setIsCheckingWrong(false);
                              setConnectAccountsButtonClicked(false);
                              setAccountType({
                                type: AccountType.SAVING,
                                clicked: true,
                              });
                              setAccountLoadMsg("");
                              setAccountErrorType("");
                              setIsSavingsWrong(false);
                              setTypeAcc("savings");
                              if (savingsAccounts.length === 0) {
                                setIsPlaidAuthOpen(true);
                              } else {
                                handleModalOpen(AccountType.SAVING);
                              }
                            }}
                            showChangeAccountButton={shouldShowChangeAccount}
                            cacheType="Savings"
                            isSavingsModule={true}
                            active={isSavingsBtnActive}
                            errorCard={
                              accountErrorType === AccountType.SAVING &&
                              isSavingCardClicked
                            }
                            item={savingsAccount}
                            selectHandler={() => {
                              setAccountType({
                                type: AccountType.SAVING,
                                clicked: true,
                              });
                              handleModalOpen(AccountType.SAVING);
                              setIsSavingsWrong(false);
                              setTypeAcc(AccountType.SAVING);
                              setTypeAcc(AccountType.SAVING);
                            }}
                          />
                        </>
                      ) : (
                        <DemoConnectionButton
                          className={
                            accountLoadMsg === "STRANGER_ACCOUNT" &&
                            accountErrorType === "savings" &&
                            connectAccountsButtonClicked &&
                            "error_border"
                          }
                          accountType={"Savings Account"}
                          isActive={checkingAccount}
                          errorCard={
                            accountErrorType === "savings" &&
                            isSavingCardClicked
                          }
                          onClickHandler={() => {
                            setStrangerCheckingError(false);
                            setStrangerSavingsError(false);
                            setIsCheckingWrong(false);
                            setConnectAccountsButtonClicked(false);
                            setAccountType({
                              type: AccountType.SAVING,
                              clicked: true,
                            });
                            setAccountLoadMsg("");
                            setAccountErrorType("");
                            setIsSavingsWrong(false);
                            setTypeAcc("savings");
                            if (savingsAccounts.length === 0) {
                              setIsPlaidAuthOpen(true);
                            } else {
                              handleModalOpen(AccountType.SAVING);
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {isSavingsWrong && isSavingCardClicked && (
                    <div className="wrong__choise-hide text__content error-text-automation demo_error_text">
                      The bank that you connected does not include a savings
                      account. Please try again. If necessary, connect to
                      another institution.
                    </div>
                  )}
                  {strangerSavingsError && (
                    <div className="userName__errorMassage  account-exist ">
                      The selected savings account is already associated with
                      another Cache user. Please connect a different account to
                      continue.
                    </div>
                  )}
                  {strangerCheckingError && (
                    <div className="userName__errorMassage  account-exist demo_error_text">
                      The selected checking account is already associated with
                      another Cache user. Please connect a different account to
                      continue.
                    </div>
                  )}

                  {isCheckingWrong && isCheckingCardClicked && (
                    <div className="wrong__choise-hide text__content error-text demo_error_text">
                      The bank that you connected does not include a checking
                      account. Please try again. If necessary, connect to
                      another institution.
                    </div>
                  )}
                  {isCheckingWrong && isCheckingCardClicked && (
                    <div className="wrong__choise text__content error-text demo_error_text">
                      The bank that you connected does not include a checking
                      account. Please try again. If necessary, connect to
                      another institution.
                    </div>
                  )}
                  {isSavingsWrong && isSavingCardClicked && (
                    <div className="wrong__choise text__content error-text demo_error_text">
                      The bank that you connected does not include a savings
                      account. Please try again. If necessary, connect to
                      another institution.
                    </div>
                  )}
                </div>
                <div className="connectAccounts_container optimizationContainer optimal_balance_block">
                  <div className="page__title optimal_balance_title">
                    Optimal balance
                  </div>
                  <div className="page__subtitle optimal_balance_subtitle">
                    After analyzing your accounts, Cache recommends that you
                    maintain ${recommendedMaximalValue} in your checking account
                    to cover your expenses during the next two weeks. This
                    amount will be continuously updated based on your balances
                    and spending. If you have a different preference, you can
                    manually set this amount below.
                  </div>
                  <div className="balanceInput__holder">
                    <DemoMaximalInput
                      isOptimalAmount={true}
                      error={maximalAmountError}
                      value={maximalAmount}
                      className={classNames(
                        maximalAmountError ? "maximal_input_error" : "",
                        "maximum_amount_input",
                      )}
                      onChangeHandler={(e: any) => {
                        dispatch(setMaximalAmount(e.target.value));
                        setMaximalAmountError(false);
                      }}
                    />
                  </div>
                  {maximalAmountError && (
                    <div className="userName__errorMassage maximal_amount_error_message demo_form_error">
                      Please set the optimal balance for your automation.
                    </div>
                  )}
                </div>
                <div className="balance_info_container">
                  {renderBalanceMessage()}
                </div>
              </>
            )}
            <div className="page_blocks_automation">
              {!accountsConnected ? (
                <div
                  className={classNames(
                    "connectAccountBlock",
                    accountErrorMessage && "connectAccountBlockWithError",
                  )}
                >
                  <div className="title_automations_onboarding">
                    Connect your bank accounts
                  </div>
                  <button
                    className="connectAccBttnDemoSavings"
                    onClick={() => {
                      setIsPlaidAuthOpen(true);
                      setConnectAccountsButtonClicked(true);
                    }}
                  >
                    Connect accounts
                  </button>
                  <div className="footerTextAutomation">
                    Cache uses{" "}
                    <Link
                      style={{ color: "white", fontWeight: "700" }}
                      target="_blank"
                      to="https://plaid.com/"
                    >
                      {" "}
                      Plaid
                    </Link>{" "}
                    to securely connect to over 11,000 banks.
                  </div>
                </div>
              ) : (
                <>
                  {!showOptimalBalance && (
                    <div className="automation__container automation_block">
                      <div className="demo_connectionButton__holder">
                        <div className="automations__checkingCards">
                          {isAccountListResultLoading || isPlaidAuthLoading ? (
                            <DemoAccountLoading />
                          ) : checkingAccount &&
                            Object.keys(checkingAccount).length > 0 ? (
                            <DemoAccountCard
                              changeAccount={() => {
                                setStrangerCheckingError(false);
                                setStrangerSavingsError(false);
                                setIsSavingsWrong(false);
                                setConnectAccountsButtonClicked(false);
                                setAccountLoadMsg("");
                                setAccountType({
                                  type: AccountType.CHECKING,
                                  clicked: true,
                                });
                                setAccountErrorType("");
                                setIsCheckingWrong(false);
                                setTypeAcc(AccountType.CHECKING);
                                if (checkingAccounts.length === 0) {
                                  setIsPlaidAuthOpen(true);
                                } else {
                                  handleModalOpen(AccountType.CHECKING);
                                }
                              }}
                              showChangeAccountButton={shouldShowChangeAccount}
                              cacheType="Checking"
                              isSavingsModule={true}
                              active={isCheckingBtnActive}
                              errorCard={
                                accountErrorType === AccountType.CHECKING &&
                                isCheckingCardClicked
                              }
                              item={checkingAccount}
                              selectHandler={() => {
                                setAccountType({
                                  type: AccountType.CHECKING,
                                  clicked: true,
                                });
                                handleModalOpen(AccountType.CHECKING);
                                setTypeAcc(AccountType.CHECKING);
                              }}
                            />
                          ) : (
                            <DemoConnectionButton
                              className={
                                accountLoadMsg === "STRANGER_ACCOUNT" &&
                                accountErrorType === AccountType.CHECKING &&
                                connectAccountsButtonClicked &&
                                "error_border"
                              }
                              accountType={"Checking Account"}
                              isActive={true}
                              errorCard={
                                accountErrorType === AccountType.CHECKING &&
                                isCheckingCardClicked
                              }
                              isWrongChoice={isCheckingWrong}
                              onClickHandler={() => {
                                setStrangerCheckingError(false);
                                setStrangerSavingsError(false);
                                setIsSavingsWrong(false);
                                setConnectAccountsButtonClicked(false);
                                setAccountLoadMsg("");
                                setAccountType({
                                  type: AccountType.CHECKING,
                                  clicked: true,
                                });
                                setAccountErrorType("");
                                setIsCheckingWrong(false);
                                setTypeAcc(AccountType.CHECKING);
                                if (checkingAccounts.length === 0) {
                                  setIsPlaidAuthOpen(true);
                                } else {
                                  handleModalOpen(AccountType.CHECKING);
                                }
                              }}
                            />
                          )}
                        </div>

                        <div
                          className={classNames(
                            "arrows__icon",
                            "demo_arrows_Icon",
                          )}
                        >
                          <img src={arrows} alt="arrows icon" />
                        </div>

                        <div
                          className="automations__savingsCards"
                          ref={savingsCardRef}
                        >
                          {isAccountListResultLoading || isPlaidAuthLoading ? (
                            <DemoAccountLoading />
                          ) : savingsAccount &&
                            Object.keys(savingsAccount).length > 0 ? (
                            <>
                              <DemoAccountCard
                                changeAccount={() => {
                                  setStrangerCheckingError(false);
                                  setStrangerSavingsError(false);
                                  setIsCheckingWrong(false);
                                  setConnectAccountsButtonClicked(false);
                                  setAccountType({
                                    type: AccountType.SAVING,
                                    clicked: true,
                                  });
                                  setAccountLoadMsg("");
                                  setAccountErrorType("");
                                  setIsSavingsWrong(false);
                                  setTypeAcc("savings");
                                  if (savingsAccounts.length === 0) {
                                    setIsPlaidAuthOpen(true);
                                  } else {
                                    handleModalOpen(AccountType.SAVING);
                                  }
                                }}
                                showChangeAccountButton={
                                  shouldShowChangeAccount
                                }
                                cacheType="Savings"
                                isSavingsModule={true}
                                active={isSavingsBtnActive}
                                errorCard={
                                  accountErrorType === AccountType.SAVING &&
                                  isSavingCardClicked
                                }
                                item={savingsAccount}
                                selectHandler={() => {
                                  setAccountType({
                                    type: AccountType.SAVING,
                                    clicked: true,
                                  });
                                  handleModalOpen(AccountType.SAVING);
                                  setIsSavingsWrong(false);
                                  setTypeAcc(AccountType.SAVING);
                                  setTypeAcc(AccountType.SAVING);
                                }}
                              />
                            </>
                          ) : (
                            <DemoConnectionButton
                              className={
                                accountLoadMsg === "STRANGER_ACCOUNT" &&
                                accountErrorType === "savings" &&
                                connectAccountsButtonClicked &&
                                "error_border"
                              }
                              accountType={"Savings Account"}
                              isActive={checkingAccount}
                              errorCard={
                                accountErrorType === "savings" &&
                                isSavingCardClicked
                              }
                              onClickHandler={() => {
                                setStrangerCheckingError(false);
                                setStrangerSavingsError(false);
                                setIsCheckingWrong(false);
                                setConnectAccountsButtonClicked(false);
                                setAccountType({
                                  type: AccountType.SAVING,
                                  clicked: true,
                                });
                                setAccountLoadMsg("");
                                setAccountErrorType("");
                                setIsSavingsWrong(false);
                                setTypeAcc("savings");
                                if (savingsAccounts.length === 0) {
                                  setIsPlaidAuthOpen(true);
                                } else {
                                  handleModalOpen(AccountType.SAVING);
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {isSavingsWrong && isSavingCardClicked && (
                        <div className="wrong__choise-hide text__content error-text-automation">
                          The bank that you connected does not include a savings
                          account. Please try again. If necessary, connect to
                          another institution.
                        </div>
                      )}
                      {strangerSavingsError && (
                        <div className="userName__errorMassage  account-exist ">
                          The selected savings account is already associated
                          with another Cache user. Please connect a different
                          account to continue.
                        </div>
                      )}
                      {strangerCheckingError && (
                        <div className="userName__errorMassage  account-exist">
                          The selected checking account is already associated
                          with another Cache user. Please connect a different
                          account to continue.
                        </div>
                      )}

                      {isCheckingWrong && isCheckingCardClicked && (
                        <div className="wrong__choise-hide text__content error-text">
                          The bank that you connected does not include a
                          checking account. Please try again. If necessary,
                          connect to another institution.
                        </div>
                      )}
                      {isCheckingWrong && isCheckingCardClicked && (
                        <div className="wrong__choise text__content error-text demo_error_text">
                          The bank that you connected does not include a
                          checking account. Please try again. If necessary,
                          connect to another institution.
                        </div>
                      )}
                      {isSavingsWrong && isSavingCardClicked && (
                        <div className="wrong__choise text__content error-text demo_error_text">
                          The bank that you connected does not include a savings
                          account. Please try again. If necessary, connect to
                          another institution.
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
              {accountError && (
                <div className="userName__errorMassage error__message demo_form_error">
                  {accountErrorMessage}
                </div>
              )}
            </div>
          </div>
          {showCheckingAccountsModal && (
            <ModalAccountsList
              title="Checking Account"
              accountsList={checkingAccounts}
              open={showCheckingAccountsModal && !isAccountListResultLoading}
              relationType={AccountType.CHECKING}
              onClose={() => {
                handleModalClose(AccountType.CHECKING);
                setIsCheckingBtnActive(false);
                setIsSavingsBtnActive(true);
              }}
              setAccount={(selectedAccount) =>
                dispatch(setCheckingAcc(selectedAccount))
              }
              onPlaidAuthSuccess={onPlaidAuthSuccess}
              setIsPlaidAuthLoading={setIsPlaidAuthLoading}
              verifyAccountsSubtype={verifyAccountsSubtype}
            />
          )}

          {showSavingsAccountsModal && (
            <ModalAccountsList
              title="Savings Account"
              accountsList={savingsAccounts}
              open={showSavingsAccountsModal && !isAccountListResultLoading}
              relationType="savings"
              onClose={() => {
                handleModalClose(AccountType.SAVING);
                setIsSavingsBtnActive(false);
              }}
              setAccount={(selectedAccount) =>
                dispatch(setSavingsAcc(selectedAccount))
              }
              onPlaidAuthSuccess={onPlaidAuthSuccess}
              setIsPlaidAuthLoading={setIsPlaidAuthLoading}
              verifyAccountsSubtype={verifyAccountsSubtype}
            />
          )}
          <PlaidAuth
            isOpen={isPlaidAuthOpen && !isAccountListResultLoading}
            success={onPlaidAuthSuccess}
            onExit={onPlaidAuthExit}
            withoutMarkup={true}
            setIsLoading={setIsPlaidAuthLoading}
            accountLoadErrorCallback={accountLoadErrorCallback}
            preConnectedAccountsCB={verifyAccountsSubtype}
            relationType={typeAcc}
          />
          <input
            type="hidden"
            id="abandonedOnboardingStage"
            value={hiddenFieldValue}
          />
          {backButtonisClicked && accountsConnected && (
            <div
              className={classNames(
                "button__holder",
                showOptimalBalance && "button_holder_display_none",
              )}
            >
              <Button
                onClick={onContinue}
                type={"primary-btn"}
                style={{ opacity: 1 }}
              >
                Continue
              </Button>
            </div>
          )}
          {showOptimalBalance && (
            <div className={classNames("button__holder")}>
              <Button onClick={onNextHandler} type={"primary-btn"}>
                Continue
              </Button>
            </div>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default DemoCreateAutomationSavingsModule;
