import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { Toaster } from "react-hot-toast";
import PrivateRoutesLayout from "./layouts/PrivateRoutesLayout";
import SignUp from "./pages/SignUp/SignUp";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import DashboardAddAutomation from "./pages/DashboardAddAutomation/DashboardAddAutomation";
import Feedback from "./pages/DashboardAddAutomation/Feedback";
import EditAutomation from "./pages/EditAutomation/EditAutomation";
import Settings from "./pages/Settings/Settings";
import SignUpVerifyAccount from "./pages/SignUp/SignUpVerifyAccount/SignUpVerifyAccount";
import SignUpUnableVerify from "./pages/SignUp/SignUpUnableVerify/SignUpUnableVerify";
import RecoveryPasswordEmail from "./pages/ForgotPassword/RecoveryPasswordEmail/RecoveryPasswordEmail";
import ConfirmNewPassword from "./pages/ForgotPassword/ConfirmNewPassword/ConfirmNewPassword";
import Verify from "./pages/SignUp/SignUpVerifyEmail/Verify";
import SignUpVerifyAccountForm from "./pages/SignUp/SignUpVerifyAccount/SignUpVerifyAccountForm";
import NotFound from "./pages/NotFound";
import TransfersList from "./pages/TransfersList/TransfersList";
import { createTheme, ThemeProvider } from "@mui/material";
import Landing from "./pages/Landing/Landing";
import sliderIcon from "../src/assets/slider_icon.svg";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import AuthRouter from "./elements/AuthRouter/AuthRouter";
import { getAuth, signOut } from "firebase/auth";
import { resetAuth } from "./redux/sliceAuth";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { v4 as uuidv4 } from "uuid";
import { SharedLayout } from "./layouts/SharedLayout";
import { resetUserAutomation, setShowOptimalBalance } from "./redux/sliceUserAutomation";
import { setSessionId } from "./redux/sliceAppState";

import { useMixpanel } from "./helpers/mixpanel";
import { useOpenTabsCounter } from "./helpers/openTabsCounter";
import { ABANDONED_ONBOARDING_DATA } from "./helpers/utils";
import { useSendEmailInfoMutation } from "./api/apiAuth";
import moment from "moment/moment";
import Faq from "./elements/Faq/Faq";
import Blog from "./pages/Blog/Blog";
import BlogPost from "./pages/Blog/BlogPost";
import DemoWelcomePage from "./pages/DemoWelcomePage/DemoWelcomePage";
import PhoneNumberForm from "./pages/DemoSignUp/PhoneNumberForm/PhoneNumberForm";
import VerifyPhoneNumber from "./pages/DemoSignUp/VerifyPhoneNumber/VerifyPhoneNumber";
import DemoDateOfBirth from "./pages/DemoSignUp/DemoDateOfBirth/DemoDateOfBirth";
import ChooseAccountsAfterPhoneVerification from "./pages/DemoSignUp/DemoChooseCreditCrads/DemoChooseCreditCrads";
import DemoAddress from "./pages/DemoSignUp/DemoAddress/DemoAddress";
import CreditCardAutomationSetUp from "./pages/DemoSignUp/CreditCardAutomationSetUp/CreditCardAutomationSetUp";
import DemoSsn from "./pages/DemoSignUp/DemoSsn/DemoSsn";
import DemoUserForm from "./pages/DemoSignUp/DemoUserForm/DemoUserForm";
import DemoDashboard from "./pages/DemoDashboard/DemoDashboard";
import { resetCreditCardState } from "./redux/sliceCreditCards";
import { resetAccounts } from "./redux/sliceAccounts";
import DemoCreateAutomationSavingsModule from "./pages/DemoSavingsModuleSignUp/DemoSavingsModule/DemoCreateAutomationSavingsModule";
import SignUpDateBirth from "./pages/SignUp/SignUpDateBirth/SignUpDateBirth";
import SignUpAddress from "./pages/SignUp/SignUpAddress/SignUpAddress";
import SignUpSSN from "./pages/SignUp/SignUpSSN/SignUpSSN";
import SignUpVerifyEmail from "./pages/SignUp/SignUpVerifyEmail/SignUpVerifyEmail";
import SignUpEmail from "./pages/SignUp/SignUpEmail/SignUpEmail";
import DemoUserSavingsForm from "./pages/DemoSignUp/DemoUserSavingsForm/DemoUserSavingsForm";

const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 744,
      md: 1025,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#39848A",
    },
    secondary: {
      main: "#EB853F",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#303030",
          fontSize: "12px",
          padding: "8px",
          fontFamily: `"Open Sans", sans-serif`,
          textAlign: "center",
          width: "257px",
        },
        arrow: {
          color: "#303030",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          lineHeight: "24px",
          padding: "16px 32px",
          textTransform: "capitalize",
          fontWeight: 700,
          borderRadius: "8px",
          color: "#EEEEEE",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "72px !important",
          padding: "0px !important",
          borderBottom: "1px solid  #707070",
          background: "#000",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "20px !important",
          paddingRight: "20px !important",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: "1px",
        },
        track: {
          color: "#EEE",
          transform: "inherit",
          backgroundColor: "#EEE",
        },
        rail: {
          color: "#EEE",
          opacity: 1,
          transform: "inherit",
          backgroundColor: "#EEE",
        },
        mark: {
          color: "#EEE",
          width: 0,
        },
        markLabel: {
          top: "2px",
          fontSize: "16px",
          color: "#EEE",
          '&[data-index="0"]': {
            left: "-7%!important",
          },
          '&[data-index="1"]': {
            left: "107%!important",
          },
        },
        markLabelActive: {
          background: "red",
        },
        valueLabel: {
          background: "red",
          fontSize: "16px",
          top: "54px",
          backgroundColor: "transparent",
        },
        thumb: {
          width: "24px",
          height: "24px",
          backgroundImage: `url(${sliderIcon})`,
          backgroundSize: "28px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "-2px -1px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: `"Open Sans", sans-serif`,
        },
        h1: {
          fontSize: "40px",
          lineHeight: "48px",
          fontWeight: "bold",
        },
        h2: {
          fontSize: "32px",
          lineHeight: "40px",
          fontWeight: "bold",
        },
        h3: {
          fontSize: "28px",
          lineHeight: "36px",
          fontWeight: "bold",
        },
        h4: {
          fontSize: "24px",
          lineHeight: "32px",
          fontWeight: "bold",
        },
        h5: {
          fontSize: "20px",
          lineHeight: "24px",
          fontWeight: "bold",
        },
        h6: {
          fontSize: "18px",
          lineHeight: "20px",
          fontWeight: "bold",
        },
        body1: {
          fontSize: "16px",
          lineHeight: "14px",
        },
        body2: {
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          background: "#303030",
          color: "#EEE",
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: "#707070",
          },
        },
      },
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const mixpanel = useMixpanel();
  const envLogoutTimer = process.env.REACT_APP_LOGOUT_IDLE_TIMER;
  const tenMinutesTimer = 10 * 60 * 1000;
  const LOGOUT_IDLE_TIMER = envLogoutTimer ? +envLogoutTimer : tenMinutesTimer;
  const [sendEmailInfo, sendEmailInfoResult] = useSendEmailInfoMutation();

  const autoLogout = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      return;
    }
    const idToken = await user.getIdToken();
    const hiddenFieldValue =
      (document.getElementById("abandonedOnboardingStage") as HTMLInputElement)
        ?.value || null;
    const abandonedObjectData = hiddenFieldValue
      ? ABANDONED_ONBOARDING_DATA[hiddenFieldValue]
      : {};
    await sendEmailInfo({
      idToken,
      body: {
        event: "USER_ABANDONED_ONBOARDING",
        data: {
          name: user.displayName || "",
          email: user.email || "",
          lastPageViewed: abandonedObjectData?.title || "",
          registrationDate: moment(),
          abandonedStage: abandonedObjectData?.stage || "",
        },
      },
    });
    await signOut(auth)
      .then(() => {
        mixpanel("Logged_out");
        dispatch(resetAuth());
        dispatch(resetUserAutomation());
        dispatch(resetCreditCardState());
        dispatch(setShowOptimalBalance(false));
        dispatch(resetAccounts());
        // localStorage.clear();
        // sessionStorage.clear();
        navigation("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onIdle = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      autoLogout();
    }
  };

  //set current session ID to redux
  useEffect(() => {
    const sessionId = uuidv4();
    dispatch(setSessionId(sessionId));
  }, []);

  const [getTabsOpenCount] = useOpenTabsCounter();
  const logoutIfNotLastTab = () => {
    const tabsOpen = getTabsOpenCount();
    if (typeof tabsOpen !== "number" || tabsOpen > 1) {
      return;
    }
    autoLogout();
  };

  useEffect(() => {
    window.addEventListener("pagehide", logoutIfNotLastTab);
    return () => {
      window.removeEventListener("pagehide", logoutIfNotLastTab);
    };
  }, []);

  useIdleTimer({
    timeout: LOGOUT_IDLE_TIMER,
    onIdle: onIdle,
    crossTab: true,
  });

  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <Toaster
          toastOptions={{
            // duration: process.env.REACT_APP_TOAST_DURATION as number | undefined,
            duration: 6000,
          }}
        />
        <AuthProvider>
          <Routes>
            <Route element={<SharedLayout />}>
              <Route
                path="/login"
                element={
                  <AuthRouter>
                    <Login />
                  </AuthRouter>
                }
              />
              <Route
                path="/recovery-password"
                element={<RecoveryPasswordEmail />}
              />
              <Route
                path="/confirm-password"
                element={<ConfirmNewPassword />}
              />
              <Route path="/verify" element={<Verify />} />
              <Route path="/verify-account" element={<SignUpVerifyAccount />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/demo-page" element={<DemoWelcomePage />} />
              <Route
                path="/demo-user-for-savings-module"
                element={<DemoUserSavingsForm />}
              />
              <Route
                path="/demo-create-automation-savings-module"
                element={<DemoCreateAutomationSavingsModule />}
              />

              <Route
                path="/demo-savings-date-birth"
                element={<SignUpDateBirth />}
              />
              <Route path="/demo-savings-address" element={<SignUpAddress />} />
              <Route path="/demo-savings-ssn" element={<SignUpSSN />} />
              <Route
                path="/demo-verify-email"
                element={<SignUpVerifyEmail />}
              />
              <Route path="/demo-change-email" element={<SignUpEmail />} />
              <Route path="/demo-user-form" element={<DemoUserForm />} />

              <Route path="/phone-number-form" element={<PhoneNumberForm />} />
              <Route
                path="/phone-number-verification"
                element={<VerifyPhoneNumber />}
              />
              <Route path="/demo-date-birth" element={<DemoDateOfBirth />} />
              <Route
                path="/choose-accounts"
                element={<ChooseAccountsAfterPhoneVerification />}
              />
              <Route
                path="/set-up-automation"
                element={<CreditCardAutomationSetUp />}
              />
              <Route path="/demo-address" element={<DemoAddress />} />
              <Route path="/demo-ssn" element={<DemoSsn />} />
              <Route path="/demo-dashboard" element={<DemoDashboard />} />

              {/* <Route path="/signup-demo" element={<DemoUserSignUp />} /> */}

              <Route element={<PrivateRoutesLayout />}>
                <Route
                  path="/add-automation"
                  element={<DashboardAddAutomation />}
                />
                <Route path="/feedback" element={<Feedback />} />
                <Route
                  path="/automation-edit/:automationId"
                  element={<EditAutomation />}
                />
                <Route path="/settings" element={<Settings />} />
                <Route path="/automations" element={<Dashboard />} />

                <Route path="/transfers" element={<TransfersList />} />
                <Route
                  path="/signup-retry-verify"
                  element={<SignUpVerifyAccountForm />}
                />
                <Route
                  path="/signup-unable-verify"
                  element={<SignUpUnableVerify />}
                />
              </Route>
              <Route path="/" element={<Landing />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/terms-of-service" element={<TermsOfUse />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:postId" element={<BlogPost />} />
              <Route path="/faq" element={<Faq />} />
            </Route>
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
