import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Wrapper from "../elements/Wrapper";
import AnimationScan from "../elements/AnimationScan/AnimationScan";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useContext, useEffect, useState, useCallback } from "react";
import { useLazyGetUserInfoQuery } from "../api/apiAuth";
import { AuthContext } from "../context/AuthContext";
import { setUserData } from "../redux/sliceNotifications";
import { getAuth, signOut } from "firebase/auth";
import { useMixpanelPublic } from "../helpers/mixpanel";
import { resetAuth } from "../redux/sliceAuth";
import {
  resetUserAutomation,
  setAccountsConnected,
} from "../redux/sliceUserAutomation";

const SharedLayout = () => {
  const [disableScanner, setDisableScanner] = useState(true);
  const isShowScannerLoader: any = useSelector<RootState>(
    (state) => state.appState.isShowScannerLoader,
  );

  const [getUser] = useLazyGetUserInfoQuery();
  const location = useLocation();
  const dispatch = useDispatch();

  const context = useContext(AuthContext);
  const { user } = context;
  const mixpanel = useMixpanelPublic();
  const auth = getAuth();

  useEffect(() => {
    if (isShowScannerLoader) {
      setDisableScanner(false);
    }
  }, [isShowScannerLoader]);

  const handleUser = useCallback(async () => {
    try {
      const idToken = await user?.getIdToken();
      if (!idToken) {
        return;
      }
      const id = user?.uid;
      await getUser({ idToken, id }).then((resp) => {
        dispatch(setUserData(resp?.data?.user));
      });
    } catch (error) {
      const isRequestFailed = `${error}`.includes(
        "auth/network-request-failed",
      );
      if (isRequestFailed) {
        try {
          await mixpanel("Network_Request_Failed", {
            userName: user?.displayName || "N/A",
            userEmail: user?.email || "N/A",
            pageLocation: window.location?.pathname || "N/A",
            layout: "Shared",
            errorMsg: `${error}`,
          });
        } catch (error) {
          console.log("mixpanel event failed");
        }
        try {
          await signOut(auth);
          dispatch(setAccountsConnected(false));
          dispatch(resetAuth());
          dispatch(resetUserAutomation());
        } catch (error) {
          await mixpanel("Signout_Error_Shared_Layout", {
            errorMsg: `${error}`,
          });
        }
      }
    }
  }, [context]);

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath !== "/automations" && currentPath !== "/verify-account") {
      setDisableScanner(true);
    }
    if (currentPath === "/verify-account") {
      setDisableScanner(false);
    }
    if (user) {
      handleUser();
    }
  }, [location]);

  const shouldScannerShow = isShowScannerLoader && !disableScanner;

  return (
    <>
      {shouldScannerShow && (
        <Wrapper>
          <AnimationScan />
        </Wrapper>
      )}
      <div style={shouldScannerShow ? { display: "none" } : {}}>
        <Outlet />
      </div>
    </>
  );
};

export { SharedLayout };
