import "./demoConnectionButton.css";

const DemoConnectionButton = ({
  accountType,
  isActive,
  onClickHandler,
  isWrongChoice = false,
  errorCard = false,
}: any) => {
  const onPress = () => {
    if (!isActive) {
      return;
    }
    onClickHandler();
  };

  const disableSavingsButton = (
    <div className="current__account">
      <div className="current__account-title disabledBtn">
        Select Savings Account
      </div>
    </div>
  );

  return (
    <button
      onClick={onPress}
      style={isWrongChoice ? { border: "1px solid var(--error)" } : {}}
      className={`${
        !isActive
          ? "demo_connection__button connection__button-disable"
          : "demo_connection__button"
      } ${errorCard ? "card-with-error" : ""}`}
    >
      {isActive ? (
        <div className="demo_current__account">
          <div className="current__account-title">{`Select ${accountType}`}</div>
        </div>
      ) : (
        <>{disableSavingsButton}</>
      )}
    </button>
  );
};

export default DemoConnectionButton;
