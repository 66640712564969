import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetUserAutomation,
  setDisabledAutomation,
} from "../../redux/sliceUserAutomation";
import { useLazyGetUserAccountsQuery } from "../../api/apiPlaid";
import {
  setSavingsAccountList,
  setCheckingAccountList,
} from "../../redux/sliceAccounts";
import { resetAuth } from "../../redux/sliceAuth";
import {
  setFirstEnter,
  setShowReconnectNotification,
} from "../../redux/sliceNotifications";
import {
  useLazyGetAutomationsDataQuery,
  useLazyGetBalanceWidgetDataQuery,
} from "../../api/apiDashboard";
import Button from "../../elements/Button/Button";
import TransfersTable from "../../elements/TransfersTable/TransfersTable";
import Loader from "../../elements/Loader/Loader";
import "./Dashboard.css";
import NotificationBar from "../../elements/NotificationBar/NotificationBar";
import CardsBlock from "../../elements/CardsBlock/CardsBlock";
import ReconnectAccountModal from "../../elements/Modals/ReconnectAccountModal";
import { RootState } from "../../redux/store";
import notification from "../../elements/ToastNotifications/ToastNotifications";
import { AuthContext } from "../../context/AuthContext";
// @ts-ignore
import SavingTimeGraph from "../../elements/SavingTime/SavingTimeGraph";
import { setIsShowScannerLoader } from "../../redux/sliceAppState";
import { useMixpanel, useMixpanelPublic } from "../../helpers/mixpanel";
import AddAutomationModal from "../../elements/Modals/AddAutomationModal";
import HeaderMenu from "../../elements/HeaderMenu/HeaderMenu";

function Dashboard() {
  const context = useContext(AuthContext);
  const { REACT_APP_TRANSFER_AMOUNT_MAX_LIMIT = 500 } = process.env;
  const [getAutomations, getAutomationsResult] =
    useLazyGetAutomationsDataQuery();
  const [getBalanceData, getBalanceDataResult] =
    useLazyGetBalanceWidgetDataQuery();
  const [getAccountsList, accountsList] = useLazyGetUserAccountsQuery();
  const [automations, setAutomations] = useState<any[]>([]);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [hasInitialNotifShowed, setHasInialNotifShowed] = useState(false);
  const mixpanel = useMixpanel();
  const [balanceData, setBalanceData] = useState({});
  const [showInitedTransaction, setShowInitedTransaction] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(0);
  const userInfo: any = useSelector<RootState>(
    (state) => state.userNotifications.userData,
  );

  const isFirstEnter: any = useSelector<RootState>(
    (state) => state.userNotifications.firstEnter,
  );

  const disconnectedAccount: any = useSelector<RootState>(
    (state) => state.userAutomation.disabledAutomation,
  );

  const isShowReconnectModal: any = useSelector<RootState>(
    (state) => state.userNotifications.showDisconnectNotification,
  );
  const [noTransfer, setNoTransfer] = useState(false);

  const navigation = useNavigate();
  const dispatch = useDispatch();

  const automationHandler = () => {
    navigation("/add-automation");
    dispatch(resetUserAutomation());
  };
  useEffect(() => {
    return () => {
      if (isFirstEnter) {
        dispatch(setFirstEnter(false));
      }
    };
  }, []);

  useEffect(() => {
    setBalanceData(getBalanceDataResult?.data?.data || {});
  }, [getBalanceDataResult]);

  useEffect(() => {
    mixpanel("Viewed_dashboard");

    if (isFirstEnter && !hasInitialNotifShowed && userInfo?.data) {
      notification({
        message:
          "Your information has been verified. Welcome to your dashboard.",
        type: "success",
      });

      setHasInialNotifShowed(true);
    }
  }, [isFirstEnter, hasInitialNotifShowed, userInfo]);

  useEffect(() => {
    const { user } = context;
    if (!user) {
      dispatch(resetAuth());
      dispatch(resetUserAutomation());
      navigation("/login");
    } else {
      user
        .getIdToken()
        .then(async (idToken: string) => {
          await getAutomations({ idToken });
          await getAccountsList({ idToken });
          getBalanceData({ idToken });
        })
        .finally(() => {
          dispatch(setIsShowScannerLoader(false));
        });
    }
  }, [disconnectedAccount]);

  useEffect(() => {
    if (accountsList.data) {
      const checkingAccount: any = accountsList.data.data.filter(
        (i: any) => i.subtype === "checking",
      );
      const savingsAccount: any = accountsList.data.data.filter(
        (i: any) => i.subtype === "savings",
      );
      dispatch(setCheckingAccountList(checkingAccount));
      dispatch(setSavingsAccountList(savingsAccount));
    }
  }, [accountsList.data]);

  // add automation with status 'disabled' in to redux(userAutomation.disabledAutomation)
  useEffect(() => {
    if (getAutomationsResult.data) {
      const { automations, transactions, transactionsCount } =
        getAutomationsResult?.data?.data ?? {};

      setAutomations(automations);
      setTransactions(transactions);
      setTransactionsCount(transactionsCount);
      if (transactions.length === 0) {
        setNoTransfer(true);
      }
      if (automations.length && transactions.length) {
        automations.forEach((automation: any) => {
          transactions.forEach((transaction: any) => {
            if (transaction.automationId === automation.id) {
              setShowInitedTransaction(true);
              setTransactionAmount(transaction.amount);
            }
          });
        });
      }
      const reconnectionFlowAutomation = automations.filter(
        (item: any) => item?.status === "reconnection_need",
      );
      // if (disabledAutomation.length === 0){
      //   return
      // }
      dispatch(setDisabledAutomation(reconnectionFlowAutomation));
    }
  }, [getAutomationsResult.data, isFirstEnter]);

  return (
    <div>
      <HeaderMenu />
      <NotificationBar />
      {getAutomationsResult.status === "fulfilled" && (
        <>
          {automations?.length ? (
            <div className="automations__container">
              <div className="automationsBlock" id="active-automations">
                <div className="automationsBlock__header">
                  <p className="title" style={{ margin: "0px" }}>
                    Active Automations
                  </p>
                  <div className="hidden__btn">
                    <Button
                      onClick={() => {}}
                      type={"add_automation"}
                      style={{ margin: "0" }}
                    >
                      <p>+</p> Add Automation
                    </Button>
                  </div>
                </div>
                {automations.map((automation: any) => (
                  <CardsBlock
                    noTransactionInitiated={noTransfer}
                    showInitedTransaction={showInitedTransaction}
                    transactionAmount={transactionAmount}
                    automation={automation}
                    key={automation.createdAt}
                  />
                ))}
                <div className="display__btn">
                  <Button
                    onClick={() => {}}
                    type={"add_automation"}
                    style={{ margin: "0" }}
                  >
                    <p>+</p> Add Automation
                  </Button>
                </div>
                <div id="latest-transfers">
                  <TransfersTable transfersData={transactions} />
                </div>
                {transactionsCount > 3 && (
                  <div className="viewMoreBlock">
                    <Link
                      to="/transfers"
                      className="viewMore"
                      onClick={() => {
                        mixpanel("Clicked_view_more_transfers");
                      }}
                    >
                      View More
                    </Link>
                  </div>
                )}
                <div id="savings-graph">
                  <SavingTimeGraph balanceData={balanceData} />
                  {/* <InterestChart /> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="automations__empty">
              <div className="dashboard__infoContainer">
                <div className="automations__empty-info">
                  <div className="page__title smaller-size">
                    You could be saving more.{" "}
                  </div>
                  <div className="page__subtitle smaller-size">
                    Cache can boost your savings with zero time and effort.
                  </div>
                </div>
                <div className="automations__addButtonHolder">
                  <Button onClick={() => {}} type={"add_automation"}>
                    <p>+</p> Add Automation
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {isShowReconnectModal && disconnectedAccount.length !== 0 && (
        <ReconnectAccountModal
          open={isShowReconnectModal && disconnectedAccount.length !== 0}
          onHide={() => {
            dispatch(setShowReconnectNotification(false));
            mixpanel("Clicked_Reconnect_Later");
          }}
        />
      )}

    </div>
  );
}

export default Dashboard;
