import { useEffect, useRef, useState, SyntheticEvent, useContext } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Slide,
  Slider,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import amountSavedIcon from "../../assets/amount_saved.svg";
import howItWorksIcon from "../../assets/how_it_works.svg";
import savingIcon from "../../assets/saveIcon.svg";
import saveIcon from "../../assets/save.svg";
import earnIcon from "../../assets/earn.svg";
import lockIcon from "../../assets/lock.svg";
import "./landing.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SaveMoneyResult from "./SaveMoneyResult";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// @ts-ignore
// @ts-ignore
import * as NumberFormat from "react-currency-format";
import Header from "../../elements/Header";
import Footer from "../../elements/Footer";
import { useMixpanelPublic } from "../../helpers/mixpanel";
import Testimonials from "../../elements/Testimonial/Testimonials";
import LogosSlider from "../../elements/LogosSlider/LogosSlider";
import { AuthContext } from "../../context/AuthContext";
import FaqSection from "../../elements/Faq/FaqSection";
import { useDispatch } from "react-redux";
import BannerModal from "../../elements/BannerModal/BannerModal";
import { LAUREL_ROAD_PORTAL_LINK } from "../../helpers/constants";
import { resetCreditCardState } from "../../redux/sliceCreditCards";
import { resetAuth } from "../../redux/sliceAuth";
import { resetAccounts } from "../../redux/sliceAccounts";
import { getAuth, signOut } from "firebase/auth";
import { resetUserAutomation } from "../../redux/sliceUserAutomation";
// Tawk_API chat widget
declare global {
  interface Window {
    Tawk_API: any;
  }
}

// marks for the APY calculator slider
const marks = [
  {
    value: 0.1,
    label: "0.0%",
  },
  {
    value: 5.9,
    label: "6.0%",
  },
];

const defaultErrors = {
  valueAMI: false,
  valueAME: false,
  valueAPY: false,
};
const Landing = () => {
  const mixpanelPublic = useMixpanelPublic();
  const [errors, setErrors] = useState(defaultErrors);
  const [valueAMI, setValueAMI] = useState("");
  const [valueAME, setValueAME] = useState("");
  const [valueAPY, setValueAPY] = useState(4.5);
  const [formChanged, setFormChanged] = useState(false);
  const [error, setError] = useState(false);
  const [result, setResult] = useState(0);
  const location = useLocation();
  const lastHash = useRef("");
  const context = useContext(AuthContext);
  const [showBannerModal, setShowBannerModal] = useState(false);
  const navigation = useNavigate();
  const isMobile = useMediaQuery("(max-width: 743px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const isUTMIncludedBannerTerm = location.search.includes(
    "utm_source=social&utm_medium=podcast&utm_campaign=avocadotoast&utm_content=100_bonus",
  );
  const [showBanner, setShowBanner] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetUserAutomation());
    dispatch(resetCreditCardState());
    dispatch(resetAuth());
    dispatch(resetAccounts());
  });

  useEffect(() => {
    if (typeof window.Tawk_API.showWidget === "function") {
      window.Tawk_API.showWidget();
    }
    if (isMobile && typeof window.Tawk_API.hideWidget === "function") {
      window.Tawk_API.hideWidget();
    }
  }, [window.Tawk_API, isMobile]);

  useEffect(() => {
    if (isUTMIncludedBannerTerm) {
      setShowBannerModal(true);
    }
  }, []);

  useEffect(() => {
    const { user } = context;
    if (!!user) {
      navigation("/demo-page");
    } else {
      const auth = getAuth();
      signOut(auth).then(() => {
        navigation("/demo-page");
      });
    }
  }, [context]);

  useEffect(() => {
    if (!formChanged) {
      setFormChanged(true);
    }
    if (valueAMI) {
      setErrors((prevState) => ({
        ...prevState,
        valueAMI: false,
      }));
    }
    if (valueAME) {
      setErrors((prevState) => ({
        ...prevState,
        valueAME: false,
      }));
    }
    if (valueAPY) {
      setErrors((prevState) => ({
        ...prevState,
        valueAPY: false,
      }));
    }
  }, [valueAMI, valueAME, valueAPY]);

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 100);
    }
  }, [location]);

  const calculation = ({
    averageMonthlyIncome,
    averageMonthlyExpenses,
    savingsAPY,
  }: any) => {
    const AMI = +averageMonthlyIncome;
    const AME = +averageMonthlyExpenses;
    const APY = +savingsAPY;

    const APR = (Math.pow(APY + 1, 1 / 12) - 1) * 12;
    const AMP = AMI * 0.75 - AME;
    let totalAmountSaved = 0;
    for (let i = 12; i >= 1; i--) {
      totalAmountSaved += AMP * Math.pow(1 + APR / 12, i);
    }

    mixpanelPublic("Calculation", {
      averageMonthlyIncome: AMI,
      averageMonthlyExpenses: AME,
      savingsAPY: APY,
      totalAmountSaved: totalAmountSaved,
    });

    return totalAmountSaved;
  };

  const sendData = () => {
    if (formChanged) {
      if (valueAMI && valueAME && valueAPY) {
        setErrors(defaultErrors);
        setError(false);

        mixpanelPublic("Entered_income");
        mixpanelPublic("Entered_expenses");
        mixpanelPublic("Entered_APY");

        const result = calculation({
          averageMonthlyIncome: valueAMI,
          averageMonthlyExpenses: valueAME,
          savingsAPY: valueAPY / 100,
        });

        if (result) {
          setResult(result);
          mixpanelPublic("Calculated_savings", { result: result });
          if (result <= 0) {
            mixpanelPublic("Negative_savings");
            setError(true);
          }
        } else {
          mixpanelPublic("Negative_savings");
          setError(true);
        }

        setFormChanged(false);
      } else {
        if (!valueAMI) {
          setErrors((prevState) => ({
            ...prevState,
            valueAMI: true,
          }));
        }
        if (!valueAME) {
          setErrors((prevState) => ({
            ...prevState,
            valueAME: true,
          }));
        }
        if (!valueAPY) {
          setErrors((prevState) => ({
            ...prevState,
            valueAPY: true,
          }));
        }
      }
    }
  };

  const sendLPViewed = (e: SyntheticEvent<HTMLImageElement>) => {
    mixpanelPublic("Viewed Homepage");
  };

  const handleClickedBanner = () => {
    setShowBannerModal(!showBannerModal);
    mixpanelPublic("Clicked_Avocado_Offer");
  };
  const handleClickBannerModal = () => {
    navigation(
      "/signup?utm_source=social&utm_medium=podcast&utm_campaign=avocadotoast&utm_content=100_bonus",
    );
  };

  const closeBannerModal = () => {
    setShowBannerModal(false);
    mixpanelPublic("Closed_Avocado_OfferBannerModal");
  };

  return (
    <>
      <Header
        position="sticky"
        showUtmBanner={isUTMIncludedBannerTerm}
        showBanner={showBanner}
        onClickedBannerModal={handleClickedBanner}
      />
      <Box className="landing">
        <Box className="blackBox">
          <Container>
            <Grid container alignItems="center" columnSpacing={10}>
              <Grid item md={6} xs={12}>
                <h1 className="landing_title">
                  Save{" "}
                  <Box component="span" className="primaryTextColor">
                    effortlessly.
                  </Box>
                </h1>
                <h1 className="landing_title">
                  Spend{" "}
                  <Box component="span" className="primaryTextColor">
                    confidently.
                  </Box>
                </h1>
                <Box className="landing_subtitle">
                  Cache automates your savings and earns you maximum interest,
                  with no risk.
                </Box>
                <Button
                  component={Link}
                  to="/signup"
                  variant="contained"
                  onClick={() => {
                    mixpanelPublic("Started");
                  }}
                >
                  Get Started
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <img
                  className="landing_topImage"
                  onLoad={sendLPViewed}
                  src={amountSavedIcon}
                  alt="Amount Saved"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <LogosSlider title={"We Work with 11,000+ Banks"} />
        <Box className="whiteBox">
          <Container>
            <Box>
              <Typography
                variant="h1"
                className="landing_title2"
                color="primary.main"
              >
                How Cache Works
              </Typography>
            </Box>
            <Box className="landing_subtitle2">
              Connect your checking and savings accounts, define the balance
              that you’d like to maintain in your checking account, and we’ll
              take it from there.
            </Box>
            <Grid
              container
              alignItems="center"
              columnSpacing={2}
              className="howItWork__info"
            >
              <Grid item md={7} xs={12}>
                <Box className="howItWorkLeftImg">
                  <img src={howItWorksIcon} alt="Bank and money" />
                </Box>
              </Grid>
              <Grid item md={5} xs={12}>
                <Box className="howItWorkRightText">
                  <p>
                    <strong>Have excess funds lying around?</strong>
                  </p>
                  <p>
                    Cache will automatically transfer them to savings to
                    maximize interest.
                  </p>
                  <br />
                  <p>
                    <strong>Short on funds?</strong>
                  </p>
                  <p>
                    Cache will automatically transfer funds back to your
                    checking account.
                  </p>
                </Box>
              </Grid>
            </Grid>
            <Grid container columnSpacing={4}>
              <Grid item md={4} xs={12}>
                <Box className="howItem">
                  <img src={earnIcon} alt="Earn" />
                  <Typography
                    variant="h3"
                    className="howItem__name"
                    color="primary.main"
                  >
                    Save Effortlessly
                  </Typography>
                  <p className="howItem__text">
                    Cache automatically identifies excess funds and moves them
                    to your savings, helping you capitalize on today’s high
                    interest rates, some as high as 5% APY.
                  </p>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box className="howItem">
                  <img src={saveIcon} alt="Save" />

                  <Typography
                    variant="h3"
                    className="howItem__name"
                    color="primary.main"
                  >
                    Spend Confidently
                  </Typography>
                  <p className="howItem__text">
                    Cache always has your back, automatically transferring funds
                    from your savings account to your checking account when you
                    need them.
                  </p>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box className="howItem" margin={0}>
                  <img src={lockIcon} alt="Lock" />
                  <Typography
                    variant="h3"
                    className="howItem__name"
                    color="primary.main"
                  >
                    Rest Assured
                  </Typography>
                  <p className="howItem__text">
                    Your money and information are safe: Cache only transfers
                    funds between your own accounts, and never stores your bank
                    login details.{" "}
                    <Link className="learn_more" to={"/faq"}>
                      <span>{`Learn about security >`} </span>
                    </Link>
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className="greyBox">
          <Testimonials isTablet={isTablet} isMobile={isMobile} />
        </Box>
        <Box
          id="calculation-saved-funds"
          className="blackBox"
          textAlign="center"
          style={{ overflow: "hidden" }}
        >
          <Container>
            <Box>
              <Typography
                variant="h3"
                className="sm_title"
                color="primary.main"
              >
                The Power of Automation
              </Typography>
            </Box>
            <Box className="calc__title text-center">
              <Typography
                variant="h1"
                className="landing_title2"
                style={{ maxWidth: "625px", margin: "0 auto" }}
              >
                See How Much Time & Money You Could Save with Cache
              </Typography>
            </Box>
            <Grid container justifyContent="space-between">
              <Grid item md={result > 0 ? 6 : 12} xs={12}>
                <Box className="smf">
                  <Box className="smf__input">
                    <Box className="smf__input__label">
                      Average Monthly Income (Pre-Tax)
                    </Box>
                    <NumberFormat
                      id="sum1"
                      customInput={TextField}
                      className="smf__input__field"
                      placeholder="Enter sum in $"
                      error={errors.valueAMI}
                      thousandSeparator={true}
                      decimalScale={2}
                      prefix={"$"}
                      inputMode="numeric"
                      autoComplete="off"
                      onValueChange={(values: any) => {
                        const { value } = values;
                        setValueAMI(value);
                      }}
                      isAllowed={(values: any) => {
                        const { value } = values;
                        if ((value > 0 && value <= 999999) || !value.length) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                  </Box>

                  <Box className="smf__input">
                    <Box className="smf__input__label">
                      Average Monthly Expenses
                    </Box>
                    <NumberFormat
                      id="sum2"
                      customInput={TextField}
                      className="smf__input__field"
                      placeholder="Enter sum in $"
                      error={errors.valueAME}
                      thousandSeparator={true}
                      decimalScale={2}
                      prefix={"$"}
                      inputMode="numeric"
                      autoComplete="off"
                      onValueChange={(values: any) => {
                        const { value } = values;
                        setValueAME(value);
                      }}
                      isAllowed={(values: any) => {
                        const { value } = values;
                        if ((value > 0 && value <= 999999) || !value.length) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box
                    className={`smf__input smfSlider ${
                      result > 0 ? "smallSlider" : "bigSlider"
                    }`}
                  >
                    <Box className="smf__input__label">
                      Savings APY
                      <Tooltip
                        classes={{ tooltip: "savingsApyTooltip" }}
                        title="Annual percentage yield (APY) is the effective interest rate that you earn on your money over the course of a year, including compound interest."
                        arrow
                        enterTouchDelay={0}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              textAlign: "left !important",
                              maxWidth: "308px",
                            },
                          },
                        }}
                      >
                        <InfoOutlinedIcon
                          color="primary"
                          className="smr__infoIcon"
                        />
                      </Tooltip>
                    </Box>
                    <Slider
                      size="small"
                      value={valueAPY}
                      onChange={(e) => {
                        // @ts-ignore
                        setValueAPY(e.target.value);
                      }}
                      max={5.9}
                      min={0.1}
                      step={0.1}
                      aria-label="Small"
                      valueLabelDisplay="on"
                      valueLabelFormat={(value) => (
                        <div
                          className={`c ${errors.valueAPY ? "text-error" : ""}`}
                        >
                          {value}%
                        </div>
                      )}
                      marks={marks}
                    />
                  </Box>

                  <Button
                    onClick={sendData}
                    className="headedBtn"
                    variant="contained"
                  >
                    See How Much You’ll Save
                  </Button>
                </Box>
                {error ? (
                  <Box className="smf__error primaryTextColor">
                    <b>
                      Unfortunately, saving money is not possible with the
                      values that you entered. Cache assumes a 25% average tax
                      rate on income, so please make sure to enter your pre-tax
                      earnings.
                    </b>
                  </Box>
                ) : null}
              </Grid>
              {result > 0 ? (
                <Grid item md={6} xs={12}>
                  <Slide
                    key={result}
                    direction="left"
                    in={!!result}
                    mountOnEnter
                    unmountOnExit
                    timeout={1000}
                  >
                    <div>
                      <SaveMoneyResult result={result} />
                    </div>
                  </Slide>
                </Grid>
              ) : null}
            </Grid>
          </Container>
        </Box>
        <Box className="whiteBox" textAlign="center">
          <FaqSection />
        </Box>
        <Footer />
      </Box>
      {showBannerModal && (
        <BannerModal
          onExit={closeBannerModal}
          showBannerModal={showBannerModal}
          title={
            <div className="banner__title">
              Price of Avocado Toast Bonus Offer: Get $100 to Boost Savings!
            </div>
          }
          textButton="Create a Cache Account"
          linkText="Open a Laurel Road HYSA"
          description={
            <div className="banner__description_text">
              <b>Here's How:</b>
              <ol className="numeration_description_text">
                <li>Create a free Cache account.</li>
                <li>
                  Open a free Laurel Road High Yield Savings™ Account (HYSA)
                  offering 5% APY (annual percentage yield) with no minimum and
                  no fees.
                </li>
                <li>
                  Connect your Laurel Road HYSA to Cache and receive $100!
                </li>
              </ol>
            </div>
          }
          footerText={
            <div className="terms_conditions_banner">
              <b>Terms & Conditions: </b> You must use Cache and Laurel Road in
              conjunction, by opening a new Laurel Road HYSA via the designated
              link provided, connecting it to an active Cache automation, and
              remaining connected and active for a period of 30 days. This offer
              is made by Cache and is not affiliated with Laurel Road or
              KeyBank.
            </div>
          }
          onClickMainCTABannerModal={handleClickBannerModal}
          link={LAUREL_ROAD_PORTAL_LINK}
        />
      )}
    </>
  );
};

export default Landing;
