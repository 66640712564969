import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  changeEmail,
  changeFirstName,
  changeLastName,
  setStep,
} from "../../../redux/sliceAuth";
import { StepWizardChildProps } from "react-step-wizard";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import Button from "../../../elements/Button/Button";
import "../DemoUserForm/demoUserForm.css";
import Wrapper from "../../../elements/Wrapper";
import TextInput from "../../../elements/Input/TextInput";
import { useContext, useEffect, useState } from "react";
import { useMixpanel } from "../../../helpers/mixpanel";
import PasswordInput from "../../../elements/PasswordInput/PasswordInput";
import CustomCheckbox from "../../../elements/CustomCheckbox/CustomCheckbox";
import { RootState } from "../../../redux/store";
import classNames from "classnames";
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  reauthenticateWithCredential,
  signOut,
  updateProfile,
} from "firebase/auth";
import {
  useSaveUserPersonaInfoMutation,
  useUserEditMutation,
  useUserExistMutation,
} from "../../../api/apiAuth";
import { AuthContext } from "../../../context/AuthContext";

declare global {
  interface Window {
    Tawk_API: any;
  }
}

const DemoUserSavingsForm: React.FC<Partial<StepWizardChildProps>> = ({
  currentStep,
}) => {
  const userData: any = useSelector<RootState>((state) => state.auth);
  const [userEmail, setUserEmail] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [error, setError] = useState(false);
  const authInfo: any = useSelector<RootState>((state) => state.auth);
  const [isCheck, setIsCheck] = useState(false);
  const context = useContext(AuthContext);

  const [checked, setChecked] = useState(authInfo.confirmedCachePolicy);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mixpanel = useMixpanel();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [formErrorName, setFormErrorName] = useState({
    firstName: false,
    lastName: false,
  });
  const [formErrorEmail, setFormErrorEmail] = useState({
    email: false,
  });
  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorMsgMail, setErrorMsgMail] = useState("");
  const [authPopUp, setAuthPopUp] = useState(false);
  const auth = getAuth();
  const [editUser] = useUserEditMutation();
  const [userExist] = useUserExistMutation();
  const [passwordError, setPasswordError] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyName, setEmptyName] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [errorInEveryField, setErrorInEveryField] = useState(false);
  let errorInName = false;
  let errorInMail = false;
  let errorInPassword = false;
  const [saveUserPersonalInfo] = useSaveUserPersonaInfoMutation();
  const nameRegex = /^(?![\uD800-\uDFFF].*$)[a-zA-Z-' ]+[a-zA-Z']?$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const isEmailValid = (email: string) =>
    email && emailRegex.test(email) && email.length <= 40;
  const isNameValid = (name: string) =>
    name && nameRegex.test(name) && name.length <= 40;

  const mobileScreenSize = window.screen.width < 768;

  useEffect(() => {
    if (mobileScreenSize && typeof window.Tawk_API.hideWidget === "function") {
      window.Tawk_API.hideWidget();
    }
  }, [mobileScreenSize]);

  const resetErrorOnChangeName = () => {
    if (errorMsgName) {
      setFormErrorName({
        firstName: false,
        lastName: false,
      });
      setErrorMsgName("");
    }
  };
  useEffect(() => {
    if (currentStep) {
      dispatch(setStep(currentStep));
    }
  }, [currentStep]);

  const onNextHandlerName = () => {
    if (!isNameValid(firstName) && !isNameValid(lastName)) {
      errorInName = true;
      setFormErrorName({
        firstName: true,
        lastName: true,
      });
      setErrorMsgName("Please enter your full name.");
      setEmptyName(true);
    } else if (!isNameValid(firstName)) {
      errorInName = true;
      setFormErrorName({
        firstName: true,
        lastName: false,
      });
      setErrorMsgName("Please enter your full name.");
      setEmptyName(true);
    } else if (!isNameValid(lastName)) {
      errorInName = true;
      setFormErrorName({
        firstName: false,
        lastName: true,
      });
      setErrorMsgName("Please enter your full name.");
      setEmptyName(true);
    } else {
      setErrorMsgName("");
      dispatch(changeFirstName(firstName.replace(/\s+$/, "")));
      dispatch(changeLastName(lastName.replace(/\s+$/, "")));

      mixpanel("Entered_name", {
        "First name": firstName.replace(/\s+$/, ""),
        "Last name": lastName.replace(/\s+$/, ""),
      });
      errorInName = false;
    }
  };

  const onNextHandlerEmail = async () => {
    if (!isEmailValid(userEmail)) {
      setEmptyEmail(true);
      errorInMail = true;
      setFormErrorEmail({
        email: true,
      });
      if (userEmail && !emailRegex.test(userEmail)) {
        setErrorMsgMail("Invalid email address. Please try again.");
      } else {
        setErrorMsgMail("Please enter your email.");
      }
    } else {
      setIsCheck(true);
      await userExist({ email: userEmail })
        .then((res: any) => {
          if (res?.data?.status === 200) {
            setIsCheck(false);
            setNewEmail();
            dispatch(changeEmail(userEmail));
            setFormErrorEmail({ email: false });
            setErrorMsgMail("");
            mixpanel("Entered_email", { Email: userEmail });
          } else if (res?.error) {
            setIsCheck(false);
            errorInMail = true;
            setFormErrorEmail({
              email: true,
            });
            setErrorMsgMail("It seems you already have an account with us.");
            errorInMail = true;
            setShowLogin(true);
          }
        })
        .catch((error) => {
          console.log("Error in userExist NewSignUp.tsx: ", error?.message);
          mixpanel("Error", { message: error?.message });
        });
    }
  };

  const onNextHandlerPassword = async () => {
    if (!passwordValid) {
      setPasswordError(true);
    }
    if (userEmail && userPassword && passwordValid) {
      if (!checked) {
        setError(true);
        return;
      }
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          userEmail,
          userPassword,
        );
        const user = userCredential.user;
        if (!user) {
          return;
        }
        await updateProfile(user, {
          displayName: `${firstName} ${lastName}`,
        }).then(() => console.log("Successfully updated profile"));
        const tkn = await user?.getIdToken();
        const body = {
          email: userEmail,
          firstName,
          lastName,
          uid: auth.currentUser?.uid,
          confirmedCachePolicy: checked,
        };
        window.FS.setUserVars({
          displayName: `${firstName} ${lastName}`,
          email: userEmail,
        });
        try {
          const res = (await saveUserPersonalInfo({ tkn, body })) as any;
          if (res?.data?.status !== 201) {
            console.log("User not created in DB, status not 201.");
            throw new Error("User not created in DB, status not 201");
          }
        } catch (error) {
          console.log("Error in NewSignUp NewSignUp.tsx: ", error);
          user.delete();
          throw new Error("User not created in DB, request error");
        }
        mixpanel(
          "Signed_up",
          {
            isCreateUser: true,
            firstName,
            lastName,
            email: userEmail,
          },
          tkn,
        );
      } catch (error) {
        console.log("Error in SignUpPassword NewSignUp.tsx: ", error);
        signOut(auth).then(() => {
          navigate("");
        });
      }
    } else {
      setEmptyPassword(true);
      errorInPassword = true;
      setPasswordError(true);
      setPasswordValid(false);
    }
  };

  const onNextHandlerTermsAndConditions = async () => {
    if (checked === false) {
      setError(true);
    } else {
      const { user } = context;
      user
        ?.getIdToken()
        .then(async (tkn) => {
          const body = {
            confirmedCachePolicy: true,
          };
          await editUser({ tkn, body }).then((res: any) => {
            if (res.error) {
              console.log(
                "Error in editUser AddAutomationInstruction.tsx: ",
                res.error,
              );
            } else {
              mixpanel("Agreed_to_terms", { Consented: true }, tkn);
            }
          });
        })
        .catch((error) => {
          console.log("Error in getIdToken automation instructions: ", error);
        });
    }
  };

  const onNextHandler = async () => {
    if (!firstName && !lastName && !userEmail && !userPassword) {
      setShowLogin(false);
      setFormErrorName({
        firstName: true,
        lastName: true,
      });
      setErrorMsgName("Please enter your full name.");
      setFormErrorEmail({
        email: false,
      });
      setErrorMsgMail("");
      errorInMail = false;

      setPasswordError(false);
      return;
    }

    onNextHandlerName();
    if (errorInName) {
      setShowLogin(false);
      setFormErrorEmail({ email: false });
      setPasswordError(false);
      setErrorMsgMail("");
      errorInMail = false;
      setError(false);
      return;
    }

    await onNextHandlerEmail();
    if (errorInMail) {
      setPasswordError(false);
      setFormErrorName({
        firstName: false,
        lastName: false,
      });
      setError(false);
      return;
    }
    if (firstName && lastName && userEmail && !userPassword && !errorInMail) {
      setPasswordError(true);
      return;
    }

    await onNextHandlerPassword();
    if (errorInPassword) {
      setShowLogin(false);
      setFormErrorEmail({ email: false });
      setErrorMsgMail("");
      errorInMail = false;
      setFormErrorName({
        firstName: false,
        lastName: false,
      });
      setError(false);
      return;
    }
    await onNextHandlerTermsAndConditions();
    console.log("finished to run terms and conditions");

    if (!checked) {
      setShowLogin(false);
      setFormErrorEmail({ email: false });
      setPasswordError(false);
      setErrorMsgMail("");
      errorInMail = false;
      setFormErrorName({
        firstName: false,
        lastName: false,
      });
      return;
    }

    if (!passwordValid) {
      setShowLogin(false);
      setFormErrorEmail({ email: false });
      setErrorMsgMail("");
      errorInMail = false;
      setFormErrorName({
        firstName: false,
        lastName: false,
      });
      setError(false);
      return;
    }
    if (!errorInName && !errorInMail && !errorInPassword) {
      navigate("/demo-create-automation-savings-module");
    }
  };

  const onBackHandler = () => {
    setPasswordError(false);
    navigate(-1);
  };

  const resetErrorOnChangeEmail = () => {
    if (errorMsgMail) {
      setFormErrorEmail({ email: false });
      setErrorMsgMail("");
      setShowLogin(false);
    }
  };

  const reAuthUser = (password: string) => {
    const currentUser: any = auth?.currentUser;
    const email = currentUser?.email || userData?.email;
    const credential = EmailAuthProvider.credential(email, password);
    return reauthenticateWithCredential(currentUser, credential);
  };

  const setNewEmail = () => {
    const user: any = auth?.currentUser;
    auth.currentUser?.getIdToken().then(async (tkn) => {
      const body = {
        email: userEmail,
      };
      editUser({ tkn, body })
        .then((res: any) => {
          if (res.error) {
            console.log("Error in editUser SignUpEmail.tsx: ", res.error);
            mixpanel("Error", { message: res.error });
          } else {
            dispatch(changeEmail(userEmail));
            mixpanel("Changed_email", { Email: userEmail });
          }
        })
        .catch((error) => {
          if (error.code === "auth/requires-recent-login") {
            setAuthPopUp(true);
          }
          console.log("Sign up: Error in updateEmail: ", error?.message);
          mixpanel("Error", { message: error?.message });
        });
    });
  };

  const resetErrorOnChange = () => {
    errorInPassword = false;
    setPasswordError(false);
  };

  return (
    <Wrapper>
      <SignupNavbar step={1} handler={onBackHandler} showBackButton={false} />
      <div className="signUpContainer">
        <div className="page__infoContainer">
          <div className="page__title">Create your Cache account.</div>
          <div className="page__subtitle">
            You’ll use these details to log in.
          </div>
          <div className="input__Holder">
            <div className={`customInput__holder`}>
              <TextInput
                fullWidth
                label="First Name"
                name="firstName"
                error={formErrorName.firstName}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  resetErrorOnChangeName();
                }}
              />
            </div>
            <div className={`customInput__holder`}>
              <TextInput
                fullWidth
                label="Last Name"
                name="lastName"
                error={formErrorName.lastName}
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  resetErrorOnChangeName();
                }}
              />
            </div>
          </div>

          <div className={`customInput__holder`}>
            <TextInput
              fullWidth
              label="Email"
              name="signUpEmail"
              error={formErrorEmail.email}
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
                resetErrorOnChangeEmail();
              }}
            />
          </div>
          <div
            className={classNames(
              "passwordInputOnboarding",
              errorMsgName && "pass_with_errors",
            )}
          >
            <PasswordInput
              userPassword={userPassword}
              onChange={(e: any) => {
                setUserPassword(e.target.value);
                resetErrorOnChange();
                setFormErrorName({
                  firstName: false,
                  lastName: false,
                });
                setError(false);
                setErrorMsgMail("");
              }}
              setPasswordValid={setPasswordValid}
              passwordError={passwordError}
            />
          </div>
          {passwordError && !userPassword && (
            <div className="field_error_message">Please create a password.</div>
          )}
          {errorMsgName && (
            <div className={classNames("field_error_message")}>
              {errorMsgName}
            </div>
          )}
          {errorMsgMail !== "" || showLogin ? (
            <div className="field_error_message">
              {errorMsgMail}
              {showLogin && errorMsgMail !== "" ? (
                <div className="login__link">
                  <Link to="">Log in.</Link>
                </div>
              ) : null}
            </div>
          ) : (
            ""
          )}
        </div>
        {errorInEveryField && (
          <div className={classNames("field_error_message ")}>
            {errorMsgName}
          </div>
        )}
        <div>
          <CustomCheckbox
            className="checkBoxSignUp"
            checked={checked}
            label={
              error ? (
                <span className="automation__conditions checkError">
                  I agree to Cache’s
                  <Link
                    target="_blank"
                    to="/terms-of-service"
                    style={{ color: "#f66 " }}
                  >
                    {" "}
                    terms of service
                  </Link>{" "}
                  and
                  <Link
                    target="_blank"
                    to="/privacy-policy"
                    style={{ color: "#f66 " }}
                  >
                    {" "}
                    privacy policy.
                  </Link>{" "}
                </span>
              ) : (
                <span className="automation__conditions">
                  I agree to Cache’s
                  <Link target="_blank" to="/terms-of-service">
                    {" "}
                    terms of service
                  </Link>{" "}
                  and
                  <Link target="_blank" to="/privacy-policy">
                    {" "}
                    privacy policy.
                  </Link>{" "}
                </span>
              )
            }
            onChange={(e: any) => {
              setChecked(e.target.checked);
              setError(false);
            }}
          />
        </div>
      </div>
      <div className="bttn_and_alreadyhaveacc">
        <div className="button__holder_new_user">
          <Button
            onMouseDown={() => {
              onNextHandler();
            }}
            type={"primary-btn"}
            disabled={isCheck}
          >
            Continue
          </Button>
        </div>
        <div className="haveAcc have_acc_demo">
          Already have an account? <Link to="">Log in.</Link>
        </div>
      </div>
    </Wrapper>
  );
};

export default DemoUserSavingsForm;
