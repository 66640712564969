import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../elements/Button/Button";
import SignupHeader from "../../elements/SignupHeader/SignupHeader";
import "./demoWelcomePage.css";

//redux reducers
import { setChosenExperience } from "../../redux/sliceCreditCards";

//redux selectors
import { selectCreditCardsExperienceChosen } from "../../redux/sliceCreditCards";
import { useEffect } from "react";

const DemoWelcomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const creditCardsExperienceChosen = useSelector(
    selectCreditCardsExperienceChosen,
  );
  const chooseAnOption = (option: string) => {
    if (option === "automation") {
      navigate("/demo-user-for-savings-module");
    } else if (option === "debt") {
      dispatch(setChosenExperience(true));
      navigate("/demo-user-form");
    }
  };
  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);
  return (
    <div>
      <SignupHeader />
      <div className="page__infoContainer demo_welcome_container">
        <div className="page__title demo_title">Welcome to our demo!</div>
        <div className="page__subtitle demo_subtitle">
          Please select the automation module that you want to go through.
        </div>
        <div className="demo_button_holder">
          <Button
            className="demo_button"
            onClick={() => chooseAnOption("automation")}
            style={{
              borderRadius: "16px",
              border: "1px solid var(--Primary, #39848A)",
              background: "var(--Greyscale-Greyscale-10, #303030)",
            }}
          >
            Savings Automation Module
          </Button>
          <Button
            className="demo_button"
            onClick={() => chooseAnOption("debt")}
            style={{
              borderRadius: "16px",
              border: "1px solid var(--Primary, #39848A)",
              background: "var(--Greyscale-Greyscale-10, #303030)",
            }}
          >
            Credit Card Automation Module
          </Button>
        </div>
      </div>
    </div>
  );
};
export default DemoWelcomePage;
