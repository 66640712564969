import "./demoAccountCard.css";
import getBankLogo from "../../helpers/getBankIcon";
import disconnected_icon from "../../assets/disconnect_acc_icon.svg";
import connected_icon from "../../assets/connected_icon.svg";
import classNames from "classnames";
import keyboardArrowRight from "../../assets/keyboardArrowRight.svg";
function DemoAccountCard({
  item,
  type,
  selectHandler = () => {},
  active = true,
  disconnected = false,
  reconnectionFlow = false,
  showOnlyDisconnectedIcon = false,
  editFlow = false,
  errorCard = false,
  className,
  cacheType,
  isSavingsModule,
  showChangeAccountButton,
  changeAccount,
}: any) {
  const bankLogo = getBankLogo(item?.institution?.logo, item?.institution?.id);
  const mask = item?.mask;
  const availableBalance = item?.availableBalance;

  const formattedCacheType =
    cacheType?.charAt(0).toUpperCase() + cacheType?.slice(1);

  return (
    <>
      {isSavingsModule ? (
        <button
          style={editFlow ? { cursor: "pointer" } : { cursor: "default" }}
          className={classNames(
            "savings_dashboard_disabled",
            showChangeAccountButton && "change_account_accountblock",
            `${
              !active
                ? "accountCard accountCard-disabled"
                : disconnected
                ? "accountCard disconnected_button"
                : "accountCard"
            } ${errorCard ? "card-with-error" : ""}`,
            className,
            "demo_card_savings_module",
          )}
          onClick={() => selectHandler()}
          // disabled={!active}
        >
          <div className="accountCard__info demo_card_info">
            <div className="accountCard__type">{type}</div>
            {reconnectionFlow ? (
              disconnected ? (
                <img src={disconnected_icon} alt="Disconnected Icon" />
              ) : !showOnlyDisconnectedIcon ? (
                <img src={connected_icon} alt="Connected Icon" />
              ) : (
                ""
              )
            ) : (
              <></>
            )}
            <div className="accountCard__data account_card_data_mobile">
              <div className="mobile_tablet_data">
                <div className="accountCard__bankLogo">
                  <img src={bankLogo} alt="Bank Logo" />
                </div>
                <div className="accountCard__number card_mask_for_mobile_and_tablet">
                  {formattedCacheType} {mask}
                </div>
              </div>
              <div className="accountCard__data-bank">
                <div className="accountCard__number card_mask_desktop">
                  {formattedCacheType} {mask}
                </div>
                <div className="accountCard__value">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(availableBalance ?? 0)}
                </div>
              </div>
            </div>

            <div className="change_account_block">
              <div onClick={changeAccount}>Change Account </div>
              <div className="arrow_icon">
                <img src={keyboardArrowRight} alt="Arrow icon" />
              </div>
            </div>
          </div>
        </button>
      ) : (
        <button
          style={editFlow ? { cursor: "pointer" } : { cursor: "default" }}
          className={classNames(
            `${
              !active
                ? "accountCard accountCard-disabled"
                : disconnected
                ? "accountCard disconnected_button"
                : "accountCard"
            } ${errorCard ? "card-with-error" : ""}`,
            className,
          )}
          onClick={() => selectHandler()}
          disabled={!active}
        >
          <div className="accountCard__info">
            <div className="accountCard__type">{type}</div>
            {reconnectionFlow ? (
              disconnected ? (
                <img src={disconnected_icon} alt="Disconnected Icon" />
              ) : !showOnlyDisconnectedIcon ? (
                <img src={connected_icon} alt="Connected Icon" />
              ) : (
                ""
              )
            ) : (
              <></>
            )}
            <div className="accountCard__data">
              <div className="accountCard__data-bank">
                <div className="cacheType">{formattedCacheType} account</div>
                <div className="accountCard__number">Account {mask}</div>
                <div className="accountCard__value">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(availableBalance ?? 0)}
                </div>
              </div>
              <div className="accountCard__bankLogo">
                <img src={bankLogo} alt="Bank Logo" />
              </div>
            </div>
          </div>
        </button>
      )}
    </>
  );
}

export default DemoAccountCard;
