import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Button from "../../elements/Button/Button";
// @ts-ignore
import "./login.css";
import { getAuth, signInWithEmailAndPassword, User } from "firebase/auth";
import { useState } from "react";
import Wrapper from "../../elements/Wrapper";
import PasswordInput from "../../elements/PasswordInput/PasswordInput";
import TextInput from "../../elements/Input/TextInput";
import { useMixpanel } from "../../helpers/mixpanel";

function Login() {
  const auth = getAuth();
  const navigation = useNavigate();
  const location = useLocation();
  const mixpanel = useMixpanel();
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [formError, setFormError] = useState({
    email: false,
    password: false,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isEmailValid = (email: string) => {
    return email && emailRegex.test(email);
  };

  const resetErrorOnChange = () => {
    if (errorMsg) {
      setFormError({
        email: false,
        password: false,
      });
      setErrorMsg("");
    }
  };

  const onLoginHandler = () => {
    if (!isEmailValid(userEmail) && !userPassword) {
      setFormError({
        email: true,
        password: true,
      });
      setErrorMsg("Please enter your email and password.");
    } else if (!isEmailValid(userEmail)) {
      setFormError({
        email: true,
        password: false,
      });
      setErrorMsg("Please enter your email.");
    } else if (!userPassword) {
      setFormError({
        email: false,
        password: true,
      });
      setErrorMsg("Please enter your password.");
    } else {
      setErrorMsg("");
      signInWithEmailAndPassword(auth, userEmail, userPassword)
        .then((userCredential) => {
          const user: User = userCredential.user;
          user.getIdToken().then((idToken: string) => {
            mixpanel("Logged_in", {}, idToken);
            window.FS.setUserVars({
              displayName: user.displayName || userEmail,
              email: userEmail,
            });
            const prevLocation = location.state?.from;
            if (prevLocation) {
              navigation(prevLocation, {
                replace: true,
                state: { from: prevLocation },
              });
            } else {
              navigation("/automations", {
                replace: true,
              });
            }
          });
        })
        .catch((error) => {
          if (error.message == "Firebase: Error (auth/user-not-found).") {
            setFormError({
              email: true,
              password: false,
            });
            setErrorMsg("This email is not associated with a Cache account.");
          }
          if (error.message == "Firebase: Error (auth/wrong-password).") {
            setFormError({
              email: false,
              password: true,
            });
            setErrorMsg("The password that you entered is incorrect.");
          }
        });
    }
  };

  return (
    <Wrapper>
      <div className="login__infoContainer">
        <div>
          <div className="page__title">Log in to Cache.</div>
          <div className="login__inputHolder">
            <div className={`customInput__holder`}>
              <TextInput
                fullWidth
                label="Email"
                name="loginEmail"
                error={formError.email}
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                  resetErrorOnChange();
                }}
              />
            </div>
            <div className={`customInput__holder`}>
              <PasswordInput
                userPassword={userPassword}
                onChange={(e: any) => {
                  setUserPassword(e.target.value);
                  resetErrorOnChange();
                }}
                passwordError={formError.password}
                showBottomValidation={false}
              />
            </div>
          </div>
          {errorMsg && (
            <div className="login__errorMassage error__message">{errorMsg}</div>
          )}
        </div>
      </div>
      <div className="forgotPassword">
        <Link to="/recovery-password">Forgot your password?</Link>
      </div>
      <div className="signup__link">
        Don’t have an account?&nbsp;<Link to="/">Sign up.</Link>
      </div>
      <div className="button__holder">
        <Button onClick={() => onLoginHandler()} type={"primary-btn"}>
          Log In
        </Button>
      </div>
    </Wrapper>
  );
}

export default Login;
