import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { auth } from "../../config/firebase";
import { getAuth, signOut } from "@firebase/auth";
import { resetAuth } from "../../redux/sliceAuth";
import { resetCreditCardState } from "../../redux/sliceCreditCards";
import {
  resetUserAutomation,
  setAccountsConnected,
  setShowOptimalBalance,
} from "../../redux/sliceUserAutomation";
import HelpLink from "../HelpLink/HelpLink";
import { persistor } from "../../redux/store";
// @ts-ignore

import exit_btn_icon from "../../assets/exit_btn_icon.svg";

import "./signupHeader.css";
import Header from "../Header";
import { useMixpanel } from "../../helpers/mixpanel";
import { useSendEmailInfoMutation } from "../../api/apiAuth";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import { ABANDONED_ONBOARDING_DATA } from "../../helpers/utils";

function SignupHeader({ position = "relative" }: any) {
  const context = useContext(AuthContext);
  const { user } = context;
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const mixpanel = useMixpanel();
  const [sendEmailInfo, sendEmailInfoResult] = useSendEmailInfoMutation();

  const logoutHandler = async () => {
    localStorage.clear();
    if (!user) {
      return;
    }
    const idToken = await user.getIdToken();
    mixpanel("Saved_and_exited");
    const hiddenFieldValue =
      (document.getElementById("abandonedOnboardingStage") as HTMLInputElement)
        ?.value || null;
    const abandonedObjectData = hiddenFieldValue
      ? ABANDONED_ONBOARDING_DATA[hiddenFieldValue]
      : {};
    const auth = getAuth();
    // await persistor.purge();
    await sendEmailInfo({
      idToken,
      body: {
        event: "USER_ABANDONED_ONBOARDING",
        data: {
          name: user.displayName || "",
          email: user.email || "",
          lastPageViewed: abandonedObjectData?.title || "",
          registrationDate: moment(),
          abandonedStage: abandonedObjectData?.stage || "",
        },
      },
    });
    signOut(auth)
      .then(() => {
        dispatch(resetAuth());
        dispatch(resetUserAutomation());
        dispatch(resetCreditCardState());
        dispatch(setShowOptimalBalance(false));
        dispatch(setAccountsConnected(false));
        navigation("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Header position={position}>
      <div className="signupHeader__btnHolder">
        <HelpLink />
        {auth.currentUser && (
          <button className="logout__btn" onClick={logoutHandler}>
            <img src={exit_btn_icon} alt="Exit Button" />
            Save & Exit
          </button>
        )}
      </div>
    </Header>
  );
}

export default SignupHeader;
